import FirebaseService from "./firebase.service";

const firebaseService = new FirebaseService();

try {
  firebaseService.init();
} catch (error) {
  console.log(error);
}

export default firebaseService;
export { FirebaseService };
