import { HeaderConfig } from "../globals";
import { ALL_CTAS } from "./ctas";
import { ROUTE_CONFIGS } from "./routes";
// import { MAIN_SOCIALS } from "./socials";


export const HEADER_CONFIG: HeaderConfig = {
  /// REQUIRED
  toggle: () => {},
  logoSrcSmall: "/img/AppIcons/Icon-512x512-White-Version.png",
  logoSrcSmallDark: "/img/AppIcons/Icon-512x512-Transparent-Background.png",
  logoSrcMedium: "/img/Logo/Combinatronix-Labs-side-Full-White-Version.png",
  logoSrcMediumDark: "/img/Logo/Combinatronix-Labs-side-Full.png",
  logoSrcLarge: "/img/Logo/Combinatronix-Labs-Full-White-Version.png",
  logoSrcLargeDark: "/img/Logo/Combinatronix-Labs-Full.png",
  show: true,
  showSub: false,
  navItems: ROUTE_CONFIGS,

  /// OPTIONAL
  // height: "var(--header-height)",
  // initHeight: "var(--header-height-initial)",
  cta: ALL_CTAS["contactUs"],
  // socials: Object.keys(MAIN_SOCIALS).map((k) => MAIN_SOCIALS[k]),
  hoverEffects: {
    textColor: "var(--header-nav-item-text-color)",
    backgroundColor: "var(--site-wide-hover-rgba)",
  },
};
