import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  BaseElementProps,
  BaseRouterLink,
  BaseScrollLink,
  BaseSocialLink,
  HeaderConfig,
  RouteConfig,
} from "../../../globals";

interface SideNavItemProps extends BaseElementProps {
  isActive: boolean;
}

export const SideNavItem = styled.li<SideNavItemProps>`
  position: relative;
  flex: 1 0 auto;
  margin: 10px 5px 10px 5px;
  min-height: 50px;
  text-decoration: none;
  background: transparent;
  border: ${({ isActive }) =>
    isActive ? "2px dashed var(--nav-hover-bg-rgba)" : "initial"};

  &:hover > a,
  &:hover > span {
    color: var(--text-color);
    border: 2px dashed var( --bg-secondary);
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};
  }
`;
interface SideNavDropProps extends BaseElementProps {
  dropped?: boolean;
}
export const SideNavItemDrop = styled.div<SideNavDropProps>`
  position: relative;
  left: 50px;
  width: calc(100% - 50px);
  display: ${({ dropped }) => (dropped ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem;

  z-index: var(--header-drop-z-idx);
  background: grey;
  border: 2px dashed var( --bg-secondary);
  color: var(--text-color);
`;

interface DisableLinkProps extends BaseElementProps {
  disabled?: boolean;
}
export const SideNavRouterLink = styled(BaseRouterLink)<DisableLinkProps>`
  padding: 10px 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 1rem;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
`;

export const SideNavItemDropLink = styled.span`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  color: var(--text-color-dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;

  & > span {
    margin: 0px 0px 0px 0px;
  }
`;

export const SideNavScrollLink = styled(BaseScrollLink)<BaseElementProps>`
  width: 100%;
  padding: 10px 1rem;

  color: var(--text-color-dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;

  &:hover {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};
  }
  &:active {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};
  }
`;

export const SideNavSocialLink = styled(BaseSocialLink)``;

export interface SideNavProps extends HeaderConfig {
  toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  navItems: RouteConfig[];
  hoverEffects?: { backgroundColor?: string; textColor?: string };
}
const SideNav: React.FC<SideNavProps> = ({
  toggle,
  navItems,
  hoverEffects,
}: SideNavProps) => {
  const location = useLocation();
  const [serviceMenuOn, setServiceMenuOn] = useState(false);
  const onArrowClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setServiceMenuOn(!serviceMenuOn);
  };
  const onServiceItemClick = (
    e?: any
  ) => {
    setServiceMenuOn(!serviceMenuOn);
    toggle(e);
  };
  return (
    <>
      {navItems.map(
        (item, idx) =>
          item.inNav && (
            <SideNavItem
              key={item.name + idx}
              className={"SideNavItem"}
              hoverEffects={hoverEffects}
              isActive={location.pathname === item.path}
            >
              {item.isScroll ? (
                // <SideNavItemDropLink onClick={onArrowClick}>
                //   {item.name}
                //   {serviceMenuOn ? <FaArrowUp /> : <FaArrowDown />}
                // </SideNavItemDropLink>
                <SideNavScrollLink
                  key={"HeaderNav" + item.name + idx}
                  to={item.path}
                  onClick={onServiceItemClick}
                >
                  <span>{item.name}</span>
                </SideNavScrollLink>
              ) : (
                <SideNavRouterLink
                  to={item.path}
                  id={item.name}
                  onClick={toggle}
                >
                  {item.name}
                </SideNavRouterLink>
              )}
              {item.subRoutes && (
                <SideNavItemDrop
                  dropped={serviceMenuOn}
                  className={"SideNavItemSub"}
                >
                  {item.subRoutes.map((drItem, drIdx) => (
                    <SideNavScrollLink
                      key={"SideNav" + drItem.path + drIdx}
                      to={drItem.path}
                    >
                      {drItem.name}
                    </SideNavScrollLink>
                  ))}
                </SideNavItemDrop>
              )}
            </SideNavItem>
          )
      )}
    </>
  );
};

export default SideNav;
