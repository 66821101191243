import React from "react";
import styled from "styled-components";

import { BaseElementProps, HeroConfig } from "../../../../globals";
import { BaseContainer } from "../../heroElements";

interface ImageContainerProps extends BaseElementProps {
  src?: string;
  grad?: {
    from: string;
    to: string;
    fromColor: string;
    toColor: string;
  };
  bgColor?: string;
  bgPosition?: string;
  bgRepeat?: string;
  bgSize?: string;
  filter?: string;
}
export const ImageContainer = styled(BaseContainer)<ImageContainerProps>`
  background-color: ${({ bgColor }) => bgColor || "transparent"};
  background-image: ${({ src, grad }) =>
    src && typeof src === "string"
      ? `url(${src})`
      : `linear-gradient(to ${grad?.from} ${grad?.to},${grad?.fromColor},${grad?.toColor})`};
  background-position: ${({ bgPosition }) => bgPosition || "center"};
  background-repeat: ${({ bgRepeat }) => bgRepeat || "no-repeat"};
  background-size: ${({ bgSize }) => bgSize || "contain"};
  background-origin: content-box;
  background-blend-mode: normal;

  backdrop-filter: ${({ filter }) => filter || "blur(0rem)"};
  filter: ${({ filter }) => filter || "blur(0rem)"};
  -webkit-filter: ${({ filter }) => filter || "blur(0rem)"};

  position: absolute;
  width: 100%;
  height: 100%;
`;

export interface ImageHeroProps extends HeroConfig {
  children?: React.ReactNode;
  [key: string]: any;
}
const ImageHero = React.forwardRef(
  (
    { children, bgImage, image, ...props }: ImageHeroProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    // console.log({ children, image, bgImage, ...props });
    return (
      <ImageContainer
        ref={ref}
        className="ImageContainer"
        height={"100%"}
        {...props}
        {...image}
        {...bgImage}
      >
        {children}
      </ImageContainer>
    );
  }
);

export default ImageHero;
