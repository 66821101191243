import React from "react";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";
import { IconType } from "react-icons";

/**
 * FrameConfig
  @key src: string;
  @key width?: string;
  @key height?: string;
  @key frameBorder?: string;
  @key sandbox?: string;
  @key referrerPolicy?: string;
  @key maxHeight?: string;
 */
export interface FrameConfig {
  src: string;
  width?: string;
  height?: string;
  frameBorder?: string;
  sandbox?: string;
  referrerPolicy?: string;
  maxHeight?: string;
}

/**
 * BgImageConfig
  @key src?: string;
  @key grad?: {
    from: string;
    to: string;
    fromColor: string;
    toColor: string;
  };
  @key bgPosition?: string;
  @key bgRepeat?: string;
  @key bgSize?: string;
 */
export interface BgImageConfig {
  src?: string;
  grad?: {
    from: string;
    to: string;
    fromColor: string;
    toColor: string;
  };
  bgPosition?: string;
  bgRepeat?: string;
  bgSize?: string;
  bgFade?: number;
}

/**
 * ImageConfig
  @key src: string;
  @key alt: string;
  @key width?: string;
  @key height?: string;
  @key loading?: string;
  @key bgPosition?: string;
 */
export interface ImageConfig {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  loading?: string;
  bgPosition?: string;
  bgColor?: string;
  maxWidth?: string;
}

/**
 * CtaTypes
  @key Button = "button",
  @key Router = "route",
  @key Anchor = "anchor",
  @key Scroll = "scroll",
 */
export enum CtaTypes {
  Button = "_button",
  Router = "_route",
  Anchor = "_anchor",
  Scroll = "_scroll",
  Apple = "_apple",
  Google = "_google",
}

/**
 * CtaConfig
  @key type?: CtaTypes;
  @key to?: string;
  @key target?: string;
  @key title?: string;
  @key label?: string;
  @key labelFontColor?: string;
  @key bgColor?: string;
  @key rel?: string;
  @key image?: ImageConfig;
  @key [key: string]: any;
 */

export interface CtaConfig {
  type: CtaTypes;
  to: string;
  target?: string;
  title?: string;
  label: string;
  labelFontColor?: string;
  bgColor?: string;
  maxWidth?: string;
  width?: string;
  rel?: string;
  image?: ImageConfig;
  position?: TextPosition;
  // [key: string]: any;
}

/**
 * Size
  @key mobileS = 320,
  @key mobileM = 375,
  @key mobileL = 425,
  @key tabletS = 550,
  @key tablet = 768,
  @key tabletL = 896,
  @key laptop = 1024,
  @key laptopL = 440,
  @key desktop = 560,
  // 
 */
export enum Size {
  mobileS = 320,
  mobileM = 375,
  mobileL = 425,
  tabletS = 550,
  tablet = 768,
  tabletL = 896,
  laptop = 1024,
  laptopL = 440,
  desktop = 560,
}

/**
 * UntiTypes
  @key px = 'px',
  @key rem = 'rem',
  @key em = 'em'
 */
export enum UntiTypes {
  px = "px",
  rem = "rem",
  em = "em",
}

/**
 * PERMISSIONS
  @key READ_AREA = 1,
  @key WRITE_AREA = 2,
  @key READ_STAFF = 4,
  @key WRITE_STAFF = 8,
 */
export enum PERMISSIONS {
  READ_AREA = 1,
  WRITE_AREA = 2,
  READ_STAFF = 4,
  WRITE_STAFF = 8,
}

/**
 * DaysOfTheWeek
  @key sunday = "Sunday",
  @key monday = "Monday",
  @key tuesday = "Tuesday",
  @key wednesday = "Wednesday",
  @key thursday = "Thursday",
  @key friday = "Friday",
  @key saturday = "Saturday",
 */
export enum DaysOfTheWeek {
  sunday = "Sunday",
  monday = "Monday",
  tuesday = "Tuesday",
  wednesday = "Wednesday",
  thursday = "Thursday",
  friday = "Friday",
  saturday = "Saturday",
}

/**
 * TextPosition
  @key Start = "flex-start",
  @key Middle = "center",
  @key End = "flex-end",
 */
export enum TextPosition {
  Start = "flex-start",
  Middle = "center",
  End = "flex-end",
}

/**
 * TextAlignment
  @key Left = "left",
  @key Center = "center",
  @key Right = "right",
 */
export enum TextAlignment {
  Left = "left",
  Center = "center",
  Right = "right",
}

/**
 * HeroType
  @key Basic = "_",
  @key NonBasic = "*",
  @key Parallex = "parallex_",
  @key Image = 'image_',
  @key Carousel = "carousel*"
 */
export enum HeroType {
  Basic = "_",
  NonBasic = "*",
  Image = "image_",
  Text = "text_",
  Parallex = "parallex_",
  Carousel = "carousel*",
  Content = "content*",
}

/**
 * ROUTES
  @key LANDING = "/",
  @key HOME = "/home",
  @key LOGIN = "/login",
  @key SIGN_UP = "/sign-up",
  @key FEEBACK = "/feedack",
  @key PAGE_NOT_FOUND = "/*",
  @key PRIVACY = "/privacy",
  @key SUPPORT = "/support",
  @key FAQ = "/faq",
  @key TOS = "/tos",
 */
export enum ROUTES {
  LANDING = "/",
  ABOUT = "/about",
  CONTACT_US="/contact",
  HOME = "/home",
  LOGIN = "/login",
  SIGN_UP = "/sign-up",
  FEEBACK = "/feedack",
  PAGE_NOT_FOUND = "/*",
  // PAGE_NOT_FOUND = "/404",
  PRIVACY = "/privacy",
  SUPPORT = "/support",
  FAQ = "/faq",
  TOS = "/tos",
  ELSE = "/500",
}

/**
 * PageProps
  @key id?: string;
  @key path?: string;
 */
export interface PageProps {
  id: string;
  path?: ROUTES;
}

/**
 * BaseElementProps
  @key height?: string;
  @key fontColor?: string;
  @key hoverEffects?: { 
    backgroundColor?: string; 
    textColor?: string 
  };
}
 */
export interface BaseElementProps {
  hoverEffects?: { backgroundColor?: string; textColor?: string };
  fontColor?: string;
  bgColor?: string;
  height?: string;
  maxHeight?: string;
  width?: string;
  maxWidth?: string;
}

export const BaseRouterLink = styled(LinkR)<BaseElementProps>`
  height: ${({ height }) => height || "100%"};
  position: relative;
  display: flex;
  align-items: center;

  margin: 0px 0px 0px 0px;
  & > span {
    margin: 0px 0px 0px 0px;
  }
`;

export const BaseScrollLink = styled(LinkS)<BaseElementProps>`
  height: ${({ height }) => height || "100%"};
  position: relative;
  display: flex;
  align-items: center;

  margin: 0px 0px 0px 0px;
  & > span {
    margin: 0px 0px 0px 0px;
  }
`;

export const BaseSocialLink = styled.a<BaseElementProps>`
  height: ${({ height }) => height || "100%"};
  position: relative;
  display: inline-block;
  align-items: center;

  margin: 0px 3px 0px;
  & > span {
    margin: 0px 0px 0px 0px;
  }
`;

/**
 * AuthUserData
  @key uid: string;
  @key email: string;
  @key phoneNumber?: string;
  @key displayName?: string;
  @key lastSignInTime?: Date;
  @key creationTime?: Date;
  @key providerData?: FirestoreAuthUserInfo;
 */
export interface AuthUserData {
  uid: string;
  email: string;
  phoneNumber?: string;
  displayName?: string;
  lastSignInTime?: Date;
  creationTime?: Date;
  // providerData?: FirestoreAuthUserInfo;
}

/**
 * ContactCard
  @key email: {
    primary: string;
    secondary?: string;
  };
  @key phone?: {
    primary?: string;
    secondary?: string;
  };
 */
export interface ContactCard {
  email: {
    primary: string;
    secondary?: string;
  };
  phone?: {
    primary?: string;
    secondary?: string;
  };
}

/**
 * UserData
  @key meta?: MetaData;
  @key id?: string;
  @key ref?: FirestoreDocument;
  @key name: string;
  @key contact?: ContactCard;
 */
export interface UserData {
  id?: string;
  // ref?: FirestoreDocument;
  name: string;
  contact?: ContactCard;
  meta?: MetaData;
}

/**
 * MetaData
  @key active: boolean;
  @key lastUpdate: FirebaseFirestore.Timestamp;
  @key lastInactive: FirebaseFirestore.Timestamp;
 */
export interface MetaData {
  active?: boolean;
  // lastUpdate: FirebaseFirestore.Timestamp;
  // lastInactive: FirebaseFirestore.Timestamp;
}

/**
 * RouteConfig
  @key path: ROUTES | string;
  @key name: string;
  @key component?: React.FC<PageProps>;
  @key componentProps?: PageProps;
  @key isPrivate?: boolean;
  @key exact?: boolean;
  @key subRoutes?: RouteConfig[];
  @key heroConfig?: HeroConfig;
  @key [key: string]: any;
 */
export interface RouteConfig {
  path: ROUTES | string;
  name: string;
  isPrivate?: boolean;
  isScroll?: boolean;
  inNav?: boolean;
  exact?: boolean;
  component?: React.FC<PageProps>;
  componentProps?: PageProps;
  subRoutes?: RouteConfig[];
  heroConfig?: HeroConfig;
  [key: string]: any;
}

/**
 * HeroConfig
  @key src: string;
  @key alt: string;
  @key caption?: string;
  @key title?: string;
  @key sutitle?: string;
  @key body?: string;
  @key ctaUrl?: string;
  @key ctaBgColor?: string;
  @key bgColor?: string;
  @key primaryTextColor?: string;
  @key primaryTextColorDark?: string;
  @key secondaryTextColor?: string;
  @key secondaryTextColorDark?: string;
  @key [key: string]: any;
 */
export interface HeroConfig {
  type?: HeroType;
  height?: string;
  width?: string;
  bgImage?: BgImageConfig;
  image?: ImageConfig;
  iframe?: FrameConfig;
  textPosition?: TextPosition;
  caption?: string;
  title?: string;
  subtitle?: string;
  body?: string;
  ctas?: CtaConfig[];
  bgColor?: string;
  primaryTextColor?: string;
  primaryTextColorDark?: string;
  secondaryTextColor?: string;
  secondaryTextColorDark?: string;
  subHeros?: (HeroConfig | undefined)[];
}

/**
 * HeaderConfig
 *  /// REQUIRED
  @key toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  @key logoSrcSmall: string;
  @key logoSrcMedium: string;
  @key logoSrcLarge: string;
  @key show: boolean;
  @key showSub: boolean;
  @key navItems: RouteConfig[];

  /// OPTIONAL
  @key height?: string;
  @key socials?: IconBlock[];
  @key cta?: CtaConfig;
  @key hoverEffects?: {
    textColor?: string;
    backgroundColor?: string;
  };
 */
export interface HeaderConfig {
  toggle?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  logoSrcSmall?: string;
  logoSrcMedium?: string;
  logoSrcLarge?: string;
  logoSrcSmallDark?: string;
  logoSrcMediumDark?: string;
  logoSrcLargeDark?: string;
  show?: boolean;
  showSub?: boolean;
  transition?: boolean;
  hoverEffects?: {
    textColor?: string;
    backgroundColor?: string;
  };
  height?: string;
  initHeight?: string;
  navItems?: RouteConfig[];
  cta?: CtaConfig;
  socials?: IconBlock[];
}

/**
 * FooterConfig
  @key socials: IconBlock[];
 */
export interface FooterConfig {
  socials?: IconBlock[];
}

/**
 * IconBlock
  @key icon: IconType;
  @key src: string;
 */
export interface IconBlock {
  icon: IconType;
  src: string;
  [key: string]: any;
}

/**
 * InfoRequest
  @key email?: string;
  @key name?: string;
  @key phone?: string;
  @key company?: string;
  @key reason?: string;
  @key message?: string;
  @key reCaptchaToken?: string;
 */
export interface InfoRequest {
  email?: string;
  name?: string;
  phone?: string;
  company?: string;
  reason?: string;
  message?: string;
  reCaptchaToken?: string;
}
