import React, { useEffect, useReducer, useRef, useState } from "react";
import { HeroConfig } from "../../../../globals";
import ImageHero, { ImageHeroProps } from "../image";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { CarouselContainer } from "../../heroElements";

interface CarouselState {
  idx: number;
}
interface CarouselAction {
  type: CarouselActionType;
}

enum CarouselActionType {
  Forward = "next",
  Back = "previous",
}

export interface CarouselHeroProps extends HeroConfig {
  children?: React.ReactNode;
  subHeros: (HeroConfig | undefined)[];
  [key: string]: any;
}

export default function CarouselHero({ subHeros }: CarouselHeroProps) {
  const goForward = { type: CarouselActionType.Forward };
  const goBack = { type: CarouselActionType.Back };
  const initialState: CarouselState = { idx: 0 };

  const reducer = (state: CarouselState, action: CarouselAction) => {
    switch (action.type) {
      case CarouselActionType.Forward:
        return { idx: Math.abs((state.idx + 1) % subHeros.length) };
      case CarouselActionType.Back:
        return { idx: Math.abs((state.idx - 1) % subHeros.length) };
      default:
        throw new Error();
    }
  };

  const onBack = () => {
    dispatch(goBack);
  };
  const onNext = () => {
    dispatch(goForward);
  };

  const [currImg, dispatch] = useReducer(reducer, initialState);
  const [images, setImages] = useState<ImageHeroProps[]>([]);
  const [title, setTitle] = useState<string>("");
  const contentRef = useRef<HTMLDivElement>(null);
  // const [timer, setTimer] = useState<NodeJS.Timeout>();
  const onCenterClick = () => {
    const theLeft = contentRef?.current?.getElementsByClassName("left").item(0);
    const theCenter = contentRef?.current?.getElementsByClassName("center").item(0);
    const theRight = contentRef?.current?.getElementsByClassName("right").item(0);
    console.log({ theLeft, theCenter, theRight });
  };
  useEffect(() => {
    let _timer = setInterval(onNext, 10000);
    return () => clearInterval(_timer);
  });

  useEffect(() => {
    setImages(subHeros.map((hero) => hero as ImageHeroProps));
  }, [subHeros]);

  useEffect(() => {
    setTitle(images[currImg.idx]?.title || "");
  }, [currImg, images]);

  return (
    <CarouselContainer className="CarouselContainer">
      <ImageHero ref={contentRef} {...images[currImg.idx]}>
        <div className="left" onClick={onBack}>
          <MdOutlineArrowBackIosNew size={48} />
        </div>
        <div className="center" onClick={onCenterClick}>{title}</div>
        <div className="right" onClick={onNext}>
          <MdOutlineArrowForwardIos size={48} />
        </div>
      </ImageHero>
    </CarouselContainer>
  );
}
