import DOMPurify from "dompurify";
import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";
import {
  BaseElementProps,
  CtaConfig,
  CtaTypes,
  HeroConfig,
  ROUTES,
  TextAlignment,
  TextPosition,
} from "../../../../globals";
import Cta from "../../../Cta/cta";
import { BaseContainer } from "../../heroElements";

interface TextCardContainerProps extends BaseElementProps {
  useShadow?: boolean;
}

export const TextCardContainer = styled(BaseContainer)<TextCardContainerProps>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || "100%"};
  flex-direction: row;
  background-color: ${({ bgColor }) => bgColor || "var(--card-bg)"};
  box-shadow: ${({ useShadow }) =>
    useShadow ? "var(--card-boxshadow)" : "intial"};

  @media screen and (min-width: 768px) {
    /* height: fit-content; */
    /* margin: 0px 0px 0px 0px; */
    /* padding: 110px 0px 110px 0px; */
  }
  @media screen and (min-width: 1024px) {
    /* margin-right: 0px auto; */
    /* padding: 110px 64px 110px 64px; */
  } ;
`;

export const TextCardInner = styled.div`
  width: 100%;
  min-width: 222px;
  max-width: 850px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* justify-content: center; */
  align-items: center;
  margin: 10rem 0px 0px 0px;
  padding: 5rem 0rem 5rem 0rem;

  @media screen and (min-width: 768px) {
    justify-content: center;
    margin: 0rem 0px 0px 0px;
    padding: 5rem 5rem 5rem 5rem;
  } ;
`;

// const BaseColumn = styled.div`
//   flex: 1 1 auto;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   border: initial;
//   height: 100%;
//   width: 100%;
//   max-width: 100%;

//   @media screen and (min-width: 768px) {
//     max-width: 50%;
//   } ;
// `;

interface Textstyle {
  textPosition?: TextPosition;
  textAlignment?: TextAlignment;
  textSize?: number;
  captionFontColor?: string;
  titleFontColor?: string;
  subtitleFontColor?: string;
  bodyFontColor?: string;
  ctaLabelFontColor?: string;
}
export const TextRow = styled.div<Textstyle>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ textPosition }) => textPosition || TextPosition.Middle};
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 0.5rem;
  color: var(--text-color);
  letter-spacing: 0px;
  text-align: ${({ textAlignment }) => textAlignment || TextAlignment.Center};
  text-shadow: black 2px 0px 5px;

  caption {
    color: ${({ captionFontColor }) => captionFontColor || "var(--text-color)"};
    font-size: 24px;
    font-size: ${({ textSize }) =>
      textSize ? `${Math.round(textSize / 20)}px` : "24px"};
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  h1 {
    color: ${({ titleFontColor }) => titleFontColor || "var(--text-color)"};
    font-size: 37px;
    font-size: ${({ textSize }) =>
      textSize ? `${Math.round(textSize / 15)}px` : "37px"};
    font-weight: 600;
    line-height: 30px;
    line-height: 1.2;
    letter-spacing: 0.5px;
    font-style: normal;
  }
  h2 {
    color: ${({ subtitleFontColor }) =>
      subtitleFontColor || "var(--text-color)"};
    font-size: 26px;
    font-size: ${({ textSize }) =>
      textSize ? `${Math.round(textSize / 10)}px` : "26px"};
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-transform: capitalize;
    text-shadow: black 2px 0px 5px;
  }
  p {
    color: ${({ bodyFontColor }) => bodyFontColor || "var(--text-color)"};
    font-size: 15px;
    font-size: ${({ textSize }) =>
      textSize ? `${Math.round(textSize / 30)}px` : "15px"};
    font-weight: 400;
    line-height: 26px;
    text-transform: "none";
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-shadow: black 2px 0px 5px;
  }
  & > .TextCta {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* width: max-content; */
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: ${({ textPosition }) =>
      textPosition || TextPosition.Middle};
    text-align: ${({ textAlignment }) => textAlignment || TextAlignment.Center};

    h1 {
      font-size: ${({ textSize }) =>
        textSize ? `${Math.round(textSize / 12)}px` : "42px"};
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1024px) {
    /* text-align: start; */
    h1 {
      font-size: ${({ textSize }) =>
        textSize ? `${Math.round(textSize / 7)}px` : "60px"};
      font-weight: 500;
    }
  } ;
`;

/**
 * TextCardProps
  @key children?: React.ReactNode;
  @key className?: string;
  @key id?: string;
  @key height?: string;
  @key width?: string;
  @key bgColor?: string;
  @key textAlignment?: TextAlignment;
  @key textPosition?: TextPosition;
  @key caption?: string;
  @key captionFontColor?: string;
  @key title?: string;
  @key titleFontColor?: string;
  @key subtitle?: string;
  @key subtitleFontColor?: string;
  @key body?: string;
  @key bodyFontColor?: string;
  @key ctas?: CtaConfig[];
  @key [key: string]: any;
 */
export interface TextCardProps extends HeroConfig {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  height?: string;
  width?: string;
  bgColor?: string;
  textSize?: number;
  textAlignment?: TextAlignment;
  textPosition?: TextPosition;
  caption?: string;
  captionFontColor?: string;
  title?: string;
  titleFontColor?: string;
  subtitle?: string;
  subtitleFontColor?: string;
  body?: string;
  bodyFontColor?: string;
  ctas?: CtaConfig[];
  [key: string]: any;
}
export default function TextCard({
  children,
  height,
  width,
  bgColor,
  bgImage,
  textSize,
  textAlignment,
  textPosition,
  caption,
  captionFontColor,
  title,
  titleFontColor,
  subtitle,
  subtitleFontColor,
  body,
  bodyFontColor,
  ctas,
  ...props
}: TextCardProps) {
  // console.log("[ContentCard]:", {
  //   children,
  //   bgColor,
  //   height,
  //   width,
  //   textPosition,
  //   caption,
  //   captionFontColor,
  //   title,
  //   titleFontColor,
  //   subtitle,
  //   subtitleFontColor,
  //   body,
  //   bodyFontColor,
  //   ctas,
  //   props,
  // });
  const defaultTextSize: number = 500;
  return (
    <TextCardContainer className="TextCardContainer">
      <TextCardInner className="TextCardInner">
        {caption && (
          <TextRow
            captionFontColor={captionFontColor}
            textSize={textSize || defaultTextSize}
            textPosition={textPosition}
            textAlignment={textAlignment}
          >
            <caption
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(caption),
              }}
            />
          </TextRow>
        )}
        {title && (
          <TextRow
            titleFontColor={titleFontColor}
            textSize={textSize || defaultTextSize}
            textPosition={textPosition}
            textAlignment={textAlignment}
          >
            <h1
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(title),
              }}
            />
          </TextRow>
        )}
        {subtitle && (
          <TextRow
            subtitleFontColor={subtitleFontColor}
            textSize={textSize || defaultTextSize}
            textPosition={textPosition}
            textAlignment={textAlignment}
          >
            <h2
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(subtitle),
              }}
            />
          </TextRow>
        )}
        {body && (
          <TextRow
            bodyFontColor={bodyFontColor}
            textSize={textSize || defaultTextSize}
            textPosition={textPosition}
            textAlignment={textAlignment}
          >
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }} />
          </TextRow>
        )}
        {children}
        {ctas && ctas.length > 0 && <>{generateCtaRows(ctas)}</>}
      </TextCardInner>
    </TextCardContainer>
  );
}

const generateCtaRows = (ctas: CtaConfig[]) => {
  const { appStoreCtas, others } = splitCtasIntoBucket(ctas);
  return (
    <>
      {generateCtaRow(others)}
      {generateCtaRow(appStoreCtas)}
    </>
  );
};

const splitCtasIntoBucket = (ctas: CtaConfig[]) => {
  const appStoreCtas: CtaConfig[] = [];
  const others: CtaConfig[] = [];
  ctas.forEach((config: CtaConfig) => {
    if (config.type === CtaTypes.Apple || config.type === CtaTypes.Google) {
      appStoreCtas.push(config);
    } else {
      others.push(config);
    }
  });
  return { appStoreCtas, others };
};

const generateCtaRow = (configs: CtaConfig[]) => {
  return (
    <TextRow>
      {configs.map((ctaConfig, idx) => (
        <Cta
          className={"TextCta"}
          key={`${ctaConfig.label}_${idx}`}
          {...ctaConfig}
        />
      ))}
    </TextRow>
  );
};
