import { CtaConfig, CtaTypes, ROUTES } from "../globals";

export const ALL_CTAS: Record<any, CtaConfig> = {
  contactUs: {
    type: CtaTypes.Scroll,
    to: `${ROUTES.LANDING}#contact`,
    label: "Contact Us",
  },
  contactUsMailTo: {
    type: CtaTypes.Anchor,
    to: "mailto:contact-us@combinatronixlabs.org?subject=Request for Information",
    label: "Contact Us",
    target: "_blank",
    rel: "noopener noreferrer",
  },
};
