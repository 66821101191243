import { HeroProps } from "../components/Hero/hero";
import { HeroType } from "../globals";

export const THE_JUSTICE_LEAGUE: HeroProps[] = [
  {
    type: HeroType.Image,
    title: "Landing Hero",
    bgImage: {
      src: "/img/AppIcons/Icon-1024x1024-Full-White-Version.png",
      bgPosition: "top -2rem left 3rem",
      bgFade: 0.75,
    },
  },
  {
    type: HeroType.Image,
    title: "Landing Hero",
    bgImage: {
      src: "/img/AppIcons/Icon-1024x1024-Full.png",
      bgPosition: "top -2rem left 3rem",
    },
  },
];

export const TEEN_TITANS: HeroProps[] = [
  {
    type: HeroType.Parallex,
    bgImage: {
      src: "/img/Hero01-1-1-scaled.jpg",
      bgPosition: "top -2rem left 3rem",
      bgFade: 0.5,
    },
  },
  {
    type: HeroType.Parallex,
    bgImage: {
      src: "/img/Hero01-1-2.jpg",
      bgPosition: "top -2rem left 3rem",
      bgFade: 0.5,
    },
  },
];

export const EAST_TEEN_TITANS: HeroProps[] = [
  {
    type: HeroType.Image,
    image: {
      src: "/img/Hero01-1-1-scaled.jpg",
      alt: "",
    },
    bgImage: {
      src: "/img/Hero01-1-1-scaled.jpg",
      bgPosition: "top -2rem left 3rem",
    },
  },
  {
    type: HeroType.Image,
    image: {
      src: "/img/Hero01-1-2.jpg",
      alt: "",
    },
    bgImage: {
      src: "/img/Hero01-1-2.jpg",
      bgPosition: "top -2rem left 3rem",
    },
  },
];

// export const TEEN_TITANS: HeroProps[] = [
//   {
//     type: HeroType.Parallex,
//     blur: "0.5",
//     image: {
//       src: "/img/Hero01-1-1-scaled.jpg",
//       alt: "",
//     },
//     bgImage: {
//       src: "/img/Hero01-1-1-scaled.jpg",
//       bgPosition: "top -2rem left 3rem",
//     },
//   },
//   {
//     type: HeroType.Parallex,
//     blur: "0.5",
//     image: {
//       src: "/img/Hero01-1-2.jpg",
//       alt: "",
//     },
//     bgImage: {
//       src: "/img/Hero01-1-2.jpg",
//       bgPosition: "top -2rem left 3rem",
//     },
//   },
// ];

// export const EAST_TEEN_TITANS = [
//   {
//     type: HeroType.Image,
//     blur: '0.5',
//     image: {
//       src: "/img/Hero01-1-1-scaled.jpg",
//       alt: "",
//     },
//     bgImage: {
//       src: "/img/Hero01-1-1-scaled.jpg",
//       bgPosition: "top -2rem left 3rem",
//     },
//   },
//   {
//     type: HeroType.Image,
//     blur: '0.5',
//     image: {
//       src: "/img/Hero01-1-2.jpg",
//       alt: "",
//     },
//     bgImage: {
//       src: "/img/Hero01-1-2.jpg",
//       bgPosition: "top -2rem left 3rem",
//     },
//   },
// ];
