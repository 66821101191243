import React from "react";
import Hero, { HeroProps } from "../../../../components/Hero/hero";
import TextCard, { TextCardProps } from "../../../../components/Hero/templates/text";
import { HeroConfig, HeroType } from "../../../../globals";

export interface IntroductionSectionProps extends HeroProps {
  height?: string;
  heroConfig?: HeroConfig;
  title?: string;
  body?: string;
  ctaLabel?: string;
  ctaTarget?: string;
  ctaBgColor?: string;
  bgColor?: string;
  [key: string]: any;
}
export default function IntroductionSection({
  height,
  heroConfig,
  title,
  body,
  ctaLabel,
  ctaTarget,
  ctaBgColor,
  bgColor,
  ...props
}: IntroductionSectionProps) {
  // console.log({
  //   height,
  //   heroConfig,
  //   ...props,
  // });
  const textCardProps: TextCardProps = {
    height,
    title,
    titleFontColor: "var(--text-color)",
    body,
    bodyFontColor: "var(--text-color)",
    ctaLabel,
    ctaTarget,
    ctaBgColor,
    bgColor,
  };
  return (
    <Hero
      type={HeroType.Parallex}
      height={height}
      subHeros={[heroConfig]}
      {...props}
    >
      <TextCard {...textCardProps} {...props} />
    </Hero>
  );
}
