import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { BaseElementProps, BaseRouterLink, BaseScrollLink, HeaderConfig, RouteConfig } from "../../../globals";

export const NavItem = styled.li<BaseElementProps>`
  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;
    position: relative;
    flex: 1 0 auto;

    &:hover {
      transition: all 0.2s ease-in-out;
      background-color: ${({ hoverEffects }) => {
        if (typeof hoverEffects === "string") {
          return hoverEffects;
        }
        return hoverEffects?.backgroundColor || "var(--primary-dark)";
      }};
    }

    &:hover > a,
    &:hover > button {
      transition: all 0.2s ease-in-out;
      color: ${({ hoverEffects }) => {
        if (typeof hoverEffects === "string") {
          return hoverEffects;
        }
        return hoverEffects?.textColor || "var(--text-color)";
      }};
    }

    &:hover > div {
      display: block;
    }

    &:active {
      background-color: ${({ hoverEffects }) => {
        if (typeof hoverEffects === "string") {
          return hoverEffects;
        }
        return hoverEffects?.backgroundColor || "var(--primary-dark)";
      }};
    }
  }
`;

export const NavItemDrop = styled.div`
  display: none;

  position: absolute;
  right: 0;
  width: max-content;
  padding: 0.5rem;

  z-index: var(--header-z-idx);
  background: grey;
  border: 2px solid var( --bg-secondary);
  color: var(--header-nav-item-text-color);
`;

export const NavRouterLink = styled(BaseRouterLink)`
  padding: 0px 1rem;

  color: var(--header-nav-item-text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const NavScrollLink = styled(BaseScrollLink)`
  padding: 10px 1rem;

  color: var(--header-nav-item-text-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
`;

export const NavItemDropRouterLink = styled(BaseRouterLink)`
  padding: 0px 1rem;

  color: var(--header-nav-item-text-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
`;

export const NavItemDropScrollLink = styled(BaseScrollLink)`
  padding: 10px 1rem;

  color: var(--header-nav-item-text-color);
  font-size: 13px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0;
  text-decoration: none;
  text-transform: none;
`;

export interface MainNavProps extends HeaderConfig {
  hoverEffects?: { textColor?: string; backgroundColor?: string };
  navItems: RouteConfig[];
}
export const MainNav: React.FC<MainNavProps> = ({
  hoverEffects,
  navItems,
}: MainNavProps) => {
  const location = useLocation();

  return (
    <>
      {navItems.map(
        (item, idx) =>
          item.inNav && (
            <NavItem
              key={item.name + idx}
              className={"NavItem"}
              hoverEffects={hoverEffects}
            >
              {item.isScroll ? (
                <NavScrollLink
                  className={"NavScroll"}
                  to={item.path}
                  activeClass="active"
                  smooth={true}
                  offset={-100}
                  duration={400}
                >
                  <span>{item.name}</span>
                </NavScrollLink>
              ) : (
                <NavRouterLink className={"NavRoute"} to={item.path}>
                  <span>{item.name}</span>
                </NavRouterLink>
              )}
              {item.subRoutes && (
                <NavItemDrop className={"SubNavItem"}>
                  {item.subRoutes.map((drItem, drIdx) =>
                    item.isScroll ? (
                      <NavItemDropScrollLink
                        key={"HeaderNav" + drItem.name + drIdx}
                        to={drItem.path}
                      >
                        <span>{drItem.name}</span>
                      </NavItemDropScrollLink>
                    ) : (
                      <NavItemDropRouterLink
                        key={"HeaderNav" + drItem.name + drIdx}
                        to={drItem.path}
                      >
                        <span>{drItem.name}</span>
                      </NavItemDropRouterLink>
                    )
                  )}
                </NavItemDrop>
              )}
              {location.pathname === item.path && (
                <div
                  className="ActiveNavHighlight"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    borderBottom: "4px solid var(--nav-hover-bg-rgba)",
                  }}
                />
              )}
            </NavItem>
          )
      )}
    </>
  );
};
