import React from "react";
import styled from "styled-components";
import {
  CtaConfig,
  CtaTypes,
  IconBlock,
  HeaderConfig,
  RouteConfig,
  ROUTES,
} from "../../../globals";
import Cta from "../../Cta/cta";
import { SocialNav } from "./socialNav";
import { MainNav } from "./mainNav";

export const HeaderNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  cursor: pointer;
  white-space: normal;

  padding: 0px 0px 0px 0px;
  @media only screen and (min-width: 768px) {
    padding: 0px 0px 0px 0px;
  } ;
`;

export const NavMenuLeft = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  height: 100%;
  flex-grow: 1;
  /* border: 3px solid red; */

  @media only screen and (min-width: 768px) {
    flex-grow: initial;
  }
`;

export const NavMenuRight = styled.ul`
  display: none;
  height: 100%;

  @media only screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    padding: 0;
    height: 100%;
    /* border: 3px solid blue; */
  }
`;

export interface NavbarProps extends HeaderConfig {
  children: React.ReactNode;
  className: string;
  navItems: RouteConfig[];
  cta?: CtaConfig;
  socials?: IconBlock[];
}
export const Navbar: React.FC<NavbarProps> = ({
  children,
  className,
  navItems,
  cta,
  socials,
  hoverEffects,
}: NavbarProps) => (
  <HeaderNav className={className}>
    <NavMenuLeft>{children}</NavMenuLeft>
    <NavMenuRight>
      {navItems && <MainNav hoverEffects={hoverEffects} navItems={navItems} />}

      {cta && (
        <li
          className={"NavItem"}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            backgroundColor: "transparent",
          }}
        >
          <Cta
            type={cta.type}
            to={cta.to}
            label={cta.label}
            target={cta.target}
            rel={cta.rel}
          />
        </li>
      )}

      {socials && <SocialNav hoverEffects={hoverEffects} socials={socials} />}
    </NavMenuRight>
  </HeaderNav>
);
