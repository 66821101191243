import React, { useEffect } from "react";
import { Element } from "react-scroll";

import { ROUTES, PageProps } from "../../globals";
import { LandingPageRoot } from "../pageElements";
import IntroductionSection, {
  IntroductionSectionProps,
} from "./sections/introduction/introduction";
import FeaturesSection, {
  FeaturesSectionProps,
} from "./sections/features/features";
import MaidenSection, { MaidenSectionProps } from "./sections/maiden/maiden";
import TdaSection, { TdaSectionProps } from "./sections/tda/tda";
import CronosSection, { CronosSectionProps } from "./sections/cronos/cronos";
import StatisticsSection, {
  StatisticsSectionProps,
} from "./sections/statistics/statistics";
import ContactUsSection, {
  ContactUsSectionProps,
} from "./sections/contactUs/contactUs";

export interface LandingPageProps extends PageProps {
  id: string;
  path?: ROUTES;
  children?: React.ReactNode;
  introductionHeight?: string;
  introductionConfig?: IntroductionSectionProps;
  featuresHeight?: string;
  featuresConfig?: FeaturesSectionProps;
  maidenHeight?: string;
  maidenConfig?: MaidenSectionProps;
  tdaHeight?: string;
  tdaConfig?: TdaSectionProps;
  cronosHeight?: string;
  cronosConfig?: CronosSectionProps;
  statisticsHeight?: string;
  statisticsConfig?: StatisticsSectionProps;
  contactUsHeight?: string;
  contactUsConfig?: ContactUsSectionProps;
}

export const Landing: React.FC<LandingPageProps> = ({
  id,
  children,
  path,
  introductionHeight,
  introductionConfig,
  featuresHeight,
  featuresConfig,
  maidenHeight,
  maidenConfig,
  tdaHeight,
  tdaConfig,
  cronosHeight,
  cronosConfig,
  statisticsHeight,
  statisticsConfig,
  contactUsHeight,
  contactUsConfig,
}: LandingPageProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("[Landing]", {
    //   id,
    //   children,
    //   path,
    //   introductionHeight,
    //   introductionConfig,
    //   featuresHeight,
    //   featuresConfig,
    //   maidenHeight,
    //   maidenConfig,
    //   tdaHeight,
    //   tdaConfig,
    //   cronosHeight,
    //   cronosConfig,
    //   statisticsHeight,
    //   statisticsConfig,
    // });
  }, []);
  return (
    <>
      {children ? (
        <LandingPageRoot className="LandingPageRoot" id={id}>
          {children}
        </LandingPageRoot>
      ) : (
        <LandingPageRoot className="LandingPageRoot" id={id}>
          <IntroductionSection
            className={"IntroductionSection"}
            height={introductionHeight}
            {...introductionConfig}
          />
          <Element name={`${ROUTES.LANDING}#features`}>
            <FeaturesSection
              className={"FeaturesSection"}
              height={featuresHeight}
              {...featuresConfig}
            />
          </Element>
          <Element name={`${ROUTES.LANDING}#products`}>
            <MaidenSection
              className={"MaidenSection"}
              height={maidenHeight}
              {...maidenConfig}
            />
            <TdaSection
              className={"TdaSection"}
              height={tdaHeight}
              {...tdaConfig}
            />
            <CronosSection
              className={"CronosSection"}
              height={cronosHeight}
              {...cronosConfig}
            />
            <StatisticsSection
              className={"StatisticsSection"}
              height={statisticsHeight}
              {...statisticsConfig}
            />
          </Element>
          <Element name={`${ROUTES.LANDING}#contact`}>
            <ContactUsSection
              className={"ContactUsSection"}
              height={contactUsHeight}
              {...contactUsConfig}
            />
          </Element>
        </LandingPageRoot>
      )}
    </>
  );
};

export default Landing;
