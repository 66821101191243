import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroType, HeroConfig } from "../../../../globals";

export interface MaidenSectionProps extends HeroProps {
  type?: HeroType;
  height?: string;
  cardConfig?: HeroConfig;
  [key: string]: any;
}
export default function MaidenSection({
  type,
  height,
  cardConfig,
  ...props
}: MaidenSectionProps) {
  // console.log({
  //   type,
  //   height,
  //   cardConfig,
  //   ...props,
  // });
  return (
      <Hero
        type={type || HeroType.Content}
        height={height}
        subHeros={[cardConfig]}
        {...props}
      />
  );
}
