import React, { useContext } from "react";
import firebaseService, { FirebaseService } from "../services/Firebase";

const MyFire = React.createContext<FirebaseService>(firebaseService);

export const useFirebaseService = () =>
  useContext<FirebaseService>(MyFire);

export const FirebaseProvider = ({ children }: { children: any }) => (
  <MyFire.Provider value={firebaseService}>{children}</MyFire.Provider>
);
