import React from "react";
import styled from "styled-components";
import { BgImageProp, Parallax } from "react-parallax";

import { BaseElementProps, HeroConfig } from "../../../../globals";
import { BaseContainer } from "../../heroElements";

interface ParallexContainerProps extends BaseElementProps {
  bgFade?: number;
}
export const ParallexContainer = styled(BaseContainer)<ParallexContainerProps>`
  .ParallexContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: ${({ bgFade }) =>
      bgFade ? `rgba(15, 7, 6, ${bgFade})` : "initial"};
  }
`;
export interface ParallexHeroProps extends HeroConfig {
  children?: React.ReactNode;
  className?: string;
  strength?: string | number;
  subHeros?: (HeroConfig | undefined)[];
  [key: string]: any;
}
export default function ParallexHero({
  children,
  className,
  strength,
  subHeros,
  ...props
}: ParallexHeroProps) {
  // console.log("[ParallexHero]", {
  //   children,
  //   strength,
  //   subHeros,
  //   ...props,
  // });
  const imageSrc: BgImageProp = (
    subHeros && subHeros.length > 0
      ? subHeros[0]?.image?.src ||
        subHeros[0]?.bgImage?.src ||
        `linear-gradient(to ${subHeros[0]?.bgImage?.grad?.from} ${subHeros[0]?.bgImage?.grad?.to},${subHeros[0]?.bgImage?.grad?.fromColor},${subHeros[0]?.bgImage?.grad?.toColor})`
      : undefined
  ) as BgImageProp;

  const imageAlt = (
    subHeros && subHeros.length > 0 ? subHeros[0]?.image?.alt : undefined
  ) as string;

  const backgroundFade = (
    subHeros && subHeros.length > 0 ? subHeros[0]?.bgImage?.bgFade : undefined
  ) as number;

  const parallexStrength =
    strength && typeof strength === "number"
      ? strength
      : strength && typeof strength === "string"
      ? parseFloat(strength)
      : 250;

  // console.log("[ParallexHero]", {
  //   imageSrc,
  //   imageAlt,
  //   backgroundFade,
  //   parallexStrength,
  // });

  return (
    <ParallexContainer className="ParallexContainer" bgFade={backgroundFade}>
      <Parallax
        {...props}
        strength={parallexStrength}
        style={{
          height: "100%",
          width: "100%",
        }}
        bgImage={imageSrc}
        bgImageAlt={imageAlt}
        bgImageStyle={{
          backgroundAttachment: "fixed",
          backgroundPosition: "center center",
          backgroundColor: "transparent",
          backgroundSize: "cover",
        }}
        contentClassName="ParallexContent"
      >
        {children}
      </Parallax>
    </ParallexContainer>
  );
}
