import { IconType } from "react-icons";
import { IconBlock } from "../../globals";
import {
  FooterContainer,
  FooterInner,
  FooterNavWrapper,
  SqsLayout,
  SqsCol,
  SqsRow,
  SqsBlock,
  SqsBlockContent,

  FooterBackToTopContainer,
  FooterBackToTopLink,
  SqsBlockSocial,
  FooterRouterLink,
  SqsBlockLegalLinks,
  FooterExternalLink,
} from "./footerElements";

export interface FooterProps {
  socials: IconBlock[];
}

export function Footer({ socials }: FooterProps) {
  return (
    <FooterContainer id="Footer" role="contentinfo">
      <FooterInner className="FooterInner" extraPadding={!socials ? '0px 0px 0px 0px' : undefined}>
        <FooterNavWrapper className="FooterNav">{backToTop()}</FooterNavWrapper>
        <SqsLayout className="layout" id="FooterBlocks">
          <SqsRow className="row">
            {/* <SqsCol className="col">
              <SqsBlock>
                <SqsBlockContent className="legals">
                  {poweredBy()}
                  <br />
                  <br />
                  <br />
                  {copyRightPrivacyTerms()}
                </SqsBlockContent>
              </SqsBlock>
            </SqsCol> */}
            <SqsCol className="col">
              {socials && (
                <SqsBlock>
                  <SqsBlockContent className="SocialLinks" direction={"row"}>
                    {socials.map((social, idx) => {
                      return (
                        <BaseGlitchyLink
                          key={"footerSocial" + idx}
                          src={social.src}
                          Icon={social.icon}
                          size={48}
                          color={"#fff"}
                        />
                      );
                    })}
                  </SqsBlockContent>
                </SqsBlock>
              )}
              <SqsBlock>
                <SqsBlockContent className="legals">
                  {/* {poweredBy()}
                  <br />
                  <br />
                  <br /> */}
                  {copyRightPrivacyTerms()}
                </SqsBlockContent>
              </SqsBlock>
            </SqsCol>
          </SqsRow>
        </SqsLayout>
      </FooterInner>
    </FooterContainer>
  );
}

export default Footer;

interface BaseGlitchyLinkProps {
  className?: string;
  src: string;
  Icon: IconType;
  size?: number;
  color?: string;
  dark?: string;
  light?: string;
  [key: string]: any;
}
const BaseGlitchyLink = ({
  className,
  src,
  Icon,
  size,
  color,
  dark,
  light,
  ...props
}: BaseGlitchyLinkProps) => (
  <SqsBlockSocial
    // color1={dark}
    // color2={light}
    // duration={3}
    // onHover={true}
    {...props}
  >
    <FooterExternalLink
      className={className}
      href={src}
      target="_blank"
      rel="noreferrer"
    >
      <Icon size={size} color={color || "#fff"}/>
    </FooterExternalLink>
  </SqsBlockSocial>
);

const backToTop = () => (
  <nav>
    <FooterBackToTopContainer className="back-to-top">
      <FooterBackToTopLink href="#">Back To Top</FooterBackToTopLink>
    </FooterBackToTopContainer>
  </nav>
);

const poweredBy = () => (
  <SqsBlockSocial
  // color1={`#2ec7ff`}
  // color2={`#ff14a5`}
  // duration={2}
  // onHover={true}
  >
    <FooterExternalLink
      href="https://www.combinatronixlabs.org/"
      target="_blank"
      rel="noreferrer"
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        // color: "#1f3c88",
        color: "#fff",
        fontWeight: 700,
      }}
    >
      <span
        style={{
          marginBottom: "0.05rem",
          fontSize: "14",
          marginRight: "0.5rem",
        }}
      >
        Powered By
      </span>
      <img
        src={"/img/Combinatronix-Labs-Logo.png"}
        height="28"
        alt={""}
      />
    </FooterExternalLink>
  </SqsBlockSocial>
);

const copyRightPrivacyTerms = () => (
  <SqsBlockLegalLinks>
    <FooterRouterLink to="/">
      © 2022 Combinatronix Labs. All Rights Reserved
    </FooterRouterLink>
    {/* |<FooterRouterLink to="/privacy">Privacy Policy</FooterRouterLink>| */}
    {/* <FooterRouterLink to="/terms">Terms of Service</FooterRouterLink> */}
  </SqsBlockLegalLinks>
);
