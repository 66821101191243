import styled from "styled-components";
// import GlitchText from 'react-glitch-effect/core/GlitchText';
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const FooterContainer = styled.footer`
  display: flex;
  color: rgba(255, 255, 255, 0.8);
  background-color: #0f0f0f;
  /* margin-top: var(--header-height); */
`;
export const FooterInner = styled.div<{ extraPadding?: string }>`
  margin: auto;
  width: auto;
  max-width: 1080px;
  text-align: center;
  padding: 20px;

  @media only screen and (min-width: 768px) {
    padding: ${({ extraPadding }) => extraPadding || "68px 34px 10px"};
  }
`;
export const FooterNavWrapper = styled.div`
  display: block;
  position: relative;

  font-family: Source Code Pro;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-decoration: none;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
export const FooterBackToTopContainer = styled.div`
  text-align: center;
  margin-bottom: 1em;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
`;
export const FooterBackToTopLink = styled.a`
  display: block;
  padding: 0.75em 1em;
  text-decoration: none;
  border-bottom-width: 0;
  line-height: 1.25em;
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.1s 0s ease-in-out;
  outline: none;
`;

export const SqsLayout = styled.div`
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: initial;
  }
`;
export const SqsRow = styled.div`
  width: auto !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -17px;
  margin-right: -17px;
`;
export const SqsCol = styled.div`
  float: none;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 17px;
  padding-right: 17px;

  @media only screen and (min-width: 768px) {
    float: left !important;
    width: 100% !important;
  }
`;
export const SqsBlock = styled.div`
  position: relative;
  height: auto;
  /* padding-top: 17px; */
  /* padding-bottom: 17px; */
`;
export const SqsBlockContent = styled.div<{ direction?: string }>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ direction }) => direction || "column"};
  text-align: center;
  width: 100%;
  padding: 17px 0px 0px;
  flex-wrap: wrap;
`;
export const SqsBlockSocial = styled.div`
  /* z-index: 400; */
  padding: 0.5rem 0px;
  a {
    color: var(--text-color);
    text-decoration: none;
    /* background: transparent; */
    padding: 0px 0.5rem;

    i {
      color: var(--text-color);
      width: 48px;
      max-width: 100%;
      border: 0;
    }
  }
`;
// export const SqsBlockSocial = styled(GlitchText)`
//   /* z-index: 400; */
//   padding: 0.5rem 0px;
//   a {
//     color: #fff;
//     text-decoration: none;
//     /* background: transparent; */
//     padding: 0px 0.5rem;

//     i {
//       color: #fff;
//       width: 48px;
//       max-width: 100%;
//       border: 0;
//     }
//   }
// `;

export const FooterExternalLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  height: 50%;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--text-color);
`;

export const SqsBlockLegalLinks = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  flex-wrap: wrap;
  padding: 1rem 0px;
`;

export const FooterRouterLink = styled(LinkR)`
  flex: 0 0 auto;
  margin: 0;
  padding: 0 0.75rem;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--text-color);
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
`;

export const FooterScrollLink = styled(LinkS)`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 100%;

  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--text-color);
  text-decoration: none;
`;
