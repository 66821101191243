import React from "react";
import { HeroConfig, HeroType } from "../../globals";
import { HeroContainer } from "./heroElements";
import CarouselHero from "./templates/carousel";
import ImageHero from "./templates/image";
import ContentCard from "./templates/content";
import ParallexHero from "./templates/parallex";
import TextCard from "./templates/text";
// import TextCard from "./templates/text";

/**
 * HeroProps @extends HeroConfig
  @key type?: HeroType;
  @key subHeros?: (HeroConfig | undefined)[];
  @key height?: string;
  @key children?: React.ReactNode;

  HeroConfig
  @key type?: HeroType;
  @key src?: string;
  @key alt?: string;
  @key height?: string;
  @key caption?: string;
  @key title?: string;
  @key sutitle?: string;
  @key body?: string;
  @key ctaUrl?: string;
  @key ctaBgColor?: string;
  @key bgColor?: string;
  @key primaryTextColor?: string;
  @key primaryTextColorDark?: string;
  @key secondaryTextColor?: string;
  @key secondaryTextColorDark?: string;
  @key subHeros?: (HeroConfig | undefined)[];
}
 */
export interface HeroProps extends HeroConfig {
  className?: string;
  children?: React.ReactNode;
  type?: HeroType;
  height?: string;
  strength?: number | string;
  subHeros?: (HeroConfig | undefined)[];
}
export default function Hero({
  className,
  children,
  type,
  height,
  strength,
  subHeros,
  ...props
}: HeroProps) {
  // console.log("[Hero]", {
  //   children,
  //   type,
  //   height,
  //   strength,
  //   subHeros,
  //   props,
  // });
  const generateHeroFromType = (theType?: HeroType) => {
    switch (theType) {
      case HeroType.Carousel:
        return (
          <CarouselHero type={theType} subHeros={subHeros || []} {...props} />
        );
      case HeroType.Parallex:
        return (
          <ParallexHero
            type={theType}
            strenght={strength}
            subHeros={subHeros || []}
            {...props}
          >
            {children}
          </ParallexHero>
        );
      case HeroType.Content:
        return (
          <ContentCard {...{ ...props, ...(subHeros || [])[0], type: theType }}>
            {children}
          </ContentCard>
        );
      case HeroType.Text:
        return (
          <TextCard {...{ ...props, ...(subHeros || [])[0], type: theType }} />
        );
      case HeroType.Image:
        return (
          <ImageHero {...{ ...props, ...(subHeros || [])[0], type: theType }}>
            {children}
          </ImageHero>
        );
      default:
        return <>Something</>;
    }
  };

  return (
    <HeroContainer className={className} largeScreen={height}>
      {generateHeroFromType(type)}
    </HeroContainer>
  );
}
