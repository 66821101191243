import React from "react";
import styled from "styled-components";
import { CtaConfig, CtaTypes, ImageConfig, TextPosition } from "../../globals";
import { CtaAnchor, CtaButton, CtaRouter, CtaScroll } from "./ctaElements";

const CtaContainer = styled.div<{ ctaPosition?: TextPosition }>`
  flex: 0 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: ${({ ctaPosition }) => ctaPosition || "center"};
  width: 100%;
  padding: 0px;
  margin: 0px;
`;
export interface CtaProps extends CtaConfig {
  className?: string;
  onClick?: (e?: any) => Promise<void>;
  disabled?: boolean;
  image?: ImageConfig;
}
const Cta = ({
  className,
  type,
  to,
  title,
  target,
  rel,
  label,
  labelFontColor,
  bgColor,
  width,
  onClick,
  disabled,
  image,
  position,
}: CtaProps) => {
  // console.log({
  //   type,
  //   to,
  //   label,
  //   labelFontColor,
  //   bgColor,
  //   onClick,
  //   disabled,
  // });
  let MyElement: JSX.Element;
  switch (type) {
    case CtaTypes.Anchor:
      MyElement = (
        <CtaAnchor
          href={to}
          title={title}
          target={target}
          rel={rel}
          width={width}
        >
          <span>{label}</span>
        </CtaAnchor>
      );
      break;
    case CtaTypes.Button:
      MyElement = (
        <CtaButton onClick={onClick}>
          <span>{label}</span>
        </CtaButton>
      );
      break;
    case CtaTypes.Apple:
      MyElement = (
        <a href={to} target={target}>
          <img
            src={image?.src}
            alt={image?.alt}
            width={image?.width}
            height={image?.height}
            style={{
              width: image?.maxWidth,
              minWidth: image?.maxWidth,
              height: "auto",
            }}
          />
        </a>
      );
      break;
    case CtaTypes.Google:
      MyElement = (
        <a href={to} target={target}>
          <img
            src={image?.src}
            alt={image?.alt}
            width={image?.width}
            height={image?.height}
            style={{
              width: image?.maxWidth,
              minWidth: image?.maxWidth,
              height: "auto",
            }}
          />
        </a>
      );
      break;
    case CtaTypes.Router:
      MyElement = (
        <CtaRouter
          className=""
          to={to}
          style={{
            backgroundColor: bgColor,
            color: labelFontColor,
          }}
        >
          <span className="" style={{ textDecoration: "inherit" }}>
            <span className="">{label}</span>
          </span>
        </CtaRouter>
      );
      break;
    case CtaTypes.Scroll:
      MyElement = (
        <CtaScroll
          className=""
          to={to}
          style={{
            backgroundColor: bgColor,
            color: labelFontColor,
          }}
        >
          <span className="" style={{ textDecoration: "inherit" }}>
            <span className="">{label}</span>
          </span>
        </CtaScroll>
      );
      break;
    default:
      MyElement = (
        <button
          type="submit"
          onClick={onClick}
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "200px",
            height: "36px",
            color: "var(--text-color-dark)",
            backgroundColor: "var(--bg-button)",
            padding: "10px 0",
            border: "var(--border)",
            borderRadius: "5px",
            margin: "5px 0 0 15px",
          }}
        >
          <span>{label}</span>
        </button>
      );
  }
  return (
    <CtaContainer className={className} ctaPosition={position}>
      {MyElement}
    </CtaContainer>
  );
};

export default Cta;
