import { HTMLAttributeReferrerPolicy } from "react";
import { Link as LinkR } from "react-router-dom";
import DOMPurify from "dompurify";
import styled from "styled-components";
import {
  BaseElementProps,
  CtaConfig,
  CtaTypes,
  FrameConfig,
  HeroConfig,
  ImageConfig,
  TextAlignment,
  TextPosition,
} from "../../../../globals";
import ImageHero from "../image";
import Cta from "../../../Cta/cta";
import { BaseContainer } from "../../heroElements";

interface CardContainerProps extends BaseElementProps {
  useShadow?: boolean;
  useBgBlur?: boolean;
  imgStart?: boolean;
}
export const ContentCardContainer = styled(BaseContainer)<CardContainerProps>`
  position: relative;
  width: 100%;
  height: ${({ height }) => height || "100%"};
  background-color: ${({ bgColor }) => bgColor || "var(--card-bg)"};
  box-shadow: ${({ useShadow }) =>
    useShadow ? "var(--card-boxshadow)" : "intial"};
  & > .ContentCardInner {
    flex-direction: column;
    /* flex-direction: ${({ imgStart }) =>
      imgStart === true ? "column" : "column-reverse"}; */
  }

  @media screen and (min-width: 768px) {
    /* height: fit-content; */
    /* margin: 0px 0px 0px 0px; */
    /* padding: 110px 0px 110px 0px; */
    & > .ContentCardInner {
      flex-direction: ${({ imgStart }) =>
        imgStart === true ? "row" : "row-reverse"};
    }
  }
  @media screen and (min-width: 1024px) {
    /* margin-right: 0px auto; */
    /* padding: 110px 64px 110px 64px; */
    & > .ContentCardInner {
      flex-direction: ${({ imgStart }) =>
        imgStart === true ? "row" : "row-reverse"};
    }
  } ;
`;

export const ContentCardInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 2rem 1rem 2rem 1rem;
  max-width: 1200px;

  @media screen and (min-width: 768px) {
    padding: 5rem 5rem 5rem 5rem;
  }
  @media screen and (min-width: 1025px) {
    padding: 5rem 5rem 5rem 5rem;
  } ;
`;

const BaseColumn = styled.div`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: initial;
  height: 100%;
  width: 100%;
  max-width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 50%;
  } ;
`;
export const LeftColumn = styled(BaseColumn)`
  /* justify-content: flex-start; */
  /* align-content: flex-start; */
  /* align-items: flex-start; */
  margin: 0px 0px 0px 0px;
  /* padding: 0rem 0rem 0rem 0rem; */
  & > img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: rgba(255, 2555, 255, 0.2);
  }

  @media screen and (min-width: 768px) {
    max-width: 50%;
  } ;
`;

export const RightColumn = styled(BaseColumn)`
  height: 100%;
  margin: 0px 0px 0px 0px;
  padding: 1rem 1.5rem;
  background: transparent;
  @media screen and (min-width: 1024px) {
    padding: 1rem 5%;
  } ;
`;

interface Textstyle {
  imgStart?: boolean;
  textPosition?: TextPosition;
  textAlignment?: TextAlignment;
  captionFontColor?: string;
  titleFontColor?: string;
  subtitleFontColor?: string;
  bodyFontColor?: string;
  ctaLabelFontColor?: string;
}
export const TextRow = styled.div<Textstyle>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ textPosition }) => textPosition || TextPosition.Middle};
  width: 100%;
  margin: 0px 0px 0px 0px;
  padding: 1rem 0px;
  color: var(--text-color-dark);
  letter-spacing: 0px;
  text-align: ${({ textAlignment }) => textAlignment || TextAlignment.Center};

  caption {
    color: ${({ captionFontColor }) => captionFontColor || "var(--text-color-dark)"};
    font-size: 24px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  h1 {
    color: ${({ titleFontColor }) => titleFontColor || "var(--text-color-dark)"};
    font-size: 37px;
    font-weight: bold;
  }
  h2 {
    color: ${({ subtitleFontColor }) =>
      subtitleFontColor || "var(--text-color-dark)"};
    font-size: 26px;
    font-weight: 400;
    letter-spacing: 0px;
    text-transform: capitalize;
  }
  p {
    color: ${({ bodyFontColor }) => bodyFontColor || "var(--text-color-dark)"};
    font-size: 15px;
    font-weight: 400;
    text-transform: "none";
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  & > .ContentCta {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* width: max-content; */
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: ${({ imgStart, textPosition }) =>
      textPosition ||
      (imgStart === true ? TextPosition.Start : TextPosition.End)};
    text-align: ${({ imgStart, textAlignment }) =>
      textAlignment ||
      (imgStart === true ? TextAlignment.Left : TextAlignment.Right)};
    h1 {
      font-size: 42px;
      font-weight: 500;
    }
  }
  @media screen and (min-width: 1024px) {
    /* text-align: start; */
  } ;
`;

export const StyledIframe = styled.iframe<BaseElementProps>`
  width: 100%;
  max-height: ${({ maxHeight }) => maxHeight || "500px"};
`;

export const ContentFrame = ({
  className,
  frame,
  image,
}: {
  className?: string;
  frame?: FrameConfig;
  image?: ImageConfig;
}) => {
  return (
    <>
      {frame ? (
        <StyledIframe
          src={frame.src}
          width={frame.width || "100%"}
          height={frame.height || "100%"}
          frameBorder={frame.frameBorder || "0"}
          sandbox={frame.sandbox || "allow-scripts allow-same-origin"}
          referrerPolicy={
            (frame.referrerPolicy ||
              "no-referrer") as HTMLAttributeReferrerPolicy
          }
          maxHeight={frame.maxHeight || "500px"}
        >
          {image && <ImageHero image={image} />}
        </StyledIframe>
      ) : (
        <>{image && <ImageHero image={image} />}</>
      )}
    </>
  );
};

/**
 * ContentCardProps
  @key children?: React.ReactNode;
  @key className?: string;
  @key id?: string;
  @key height?: string;
  @key width?: string;
  @key bgColor?: string;
  @key caption?: string;
  @key captionFontColor?: string;
  @key title?: string;
  @key titleFontColor?: string;
  @key subtitle?: string;
  @key subtitleFontColor?: string;
  @key body?: string;
  @key bodyFontColor?: string;
  @key ctas?: CtaConfig[];
  @key useShadow?: boolean;
  @key useBgBlur?: boolean;
  @key imageStart?: boolean;
  @key [key: string]: any;
 */
export interface ContentCardProps extends HeroConfig {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  height?: string;
  width?: string;
  bgColor?: string;
  caption?: string;
  captionFontColor?: string;
  title?: string;
  titleFontColor?: string;
  subtitle?: string;
  subtitleFontColor?: string;
  body?: string;
  bodyFontColor?: string;
  ctas?: CtaConfig[];
  useShadow?: boolean;
  useBgBlur?: boolean;
  imageStart?: boolean;
  [key: string]: any;
}

export default function ContentCard({
  className,
  children,
  id,
  height,
  width,
  bgColor,
  bgImage,
  image,
  iframe,
  textPosition,
  caption,
  captionFontColor,
  title,
  titleFontColor,
  subtitle,
  subtitleFontColor,
  body,
  bodyFontColor,
  ctas,
  useShadow,
  useBgBlur,
  imageStart,
  ...props
}: ContentCardProps) {
  // console.log("[ContentCard]:", {
  //   children,
  //   id,
  //   height,
  //   width,
  //   bgColor,
  //   useShadow,
  //   useBgBlur,
  //   imageStart,
  //   image,
  //   textPosition,
  //   caption,
  //   captionFontColor,
  //   title,
  //   titleFontColor,
  //   subtitle,
  //   subtitleFontColor,
  //   body,
  //   bodyFontColor,
  //   ctas,
  //   ...props,
  // });
  return (
    <ContentCardContainer
      className="ContentCardContainer"
      id={id}
      height={height}
      bgColor={bgColor}
      useShadow={useShadow}
      imgStart={imageStart}
      useBgBlur={useBgBlur}
    >
      {bgImage && <ImageHero bgImage={bgImage} />}
      <ContentCardInner className="ContentCardInner">
        <LeftColumn className="LeftColumn">
          {children && !image && !iframe ? (
            children
          ) : (
            <ContentFrame
              className={"MediumAndLarge"}
              frame={iframe}
              image={image}
            />
          )}
        </LeftColumn>
        <RightColumn className="RightColumn">
          {caption && (
            <TextRow
              imgStart={imageStart}
              textPosition={textPosition}
              captionFontColor={captionFontColor}
            >
              <caption
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(caption),
                }}
              />
            </TextRow>
          )}
          {title && (
            <TextRow
              imgStart={imageStart}
              textPosition={textPosition}
              titleFontColor={titleFontColor}
            >
              <h1
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(title),
                }}
              />
            </TextRow>
          )}
          {subtitle && (
            <TextRow
              imgStart={imageStart}
              textPosition={textPosition}
              subtitleFontColor={subtitleFontColor}
            >
              <h2
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(subtitle),
                }}
              />
            </TextRow>
          )}
          {body && (
            <TextRow
              imgStart={imageStart}
              textPosition={textPosition}
              bodyFontColor={bodyFontColor}
            >
              <p
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(body) }}
              />
            </TextRow>
          )}
          {/* {children} */}
          {ctas && ctas.length > 0 && <>{generateCtaRows(ctas, imageStart)}</>}
        </RightColumn>
      </ContentCardInner>
    </ContentCardContainer>
  );
}

const generateCtaRows = (ctas: CtaConfig[], imageStart?: boolean) => {
  const { appStoreCtas, others } = splitCtasIntoBucket(ctas);
  return (
    <>
      {generateCtaRow(others, imageStart)}
      {generateCtaRow(appStoreCtas, imageStart)}
    </>
  );
};

const splitCtasIntoBucket = (ctas: CtaConfig[]) => {
  const appStoreCtas: CtaConfig[] = [];
  const others: CtaConfig[] = [];
  ctas.forEach((config: CtaConfig) => {
    if (config.type === CtaTypes.Apple || config.type === CtaTypes.Google) {
      appStoreCtas.push(config);
    } else {
      others.push(config);
    }
  });
  return { appStoreCtas, others };
};
const generateCtaRow = (configs: CtaConfig[], imageStart?: boolean) => {
  return (
    <TextRow imgStart={imageStart}>
      {configs.map((ctaConfig, idx) => (
        <Cta
          className={"ContentCta"}
          key={`${ctaConfig.label}_${idx}`}
          {...ctaConfig}
        />
      ))}
    </TextRow>
  );
};
