import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroType, HeroConfig } from "../../../../globals";

export interface TdaSectionProps extends HeroProps {
  type?: HeroType;
  height?: string;
  cardConfig?: HeroConfig;
  [key: string]: any;
}
export default function TdaSection({ type, height, cardConfig, ...props }: TdaSectionProps) {
  // console.log({
  //   type,
  //   height,
  //   heroConfig,
  //   ...props,
  // });
  return (
    <Hero
      type={type || HeroType.Content}
      height={height}
      subHeros={[cardConfig]}
      {...props}
    />
  );
}
