import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  BaseElementProps,
  CtaConfig,
  HeaderConfig,
  // IconBlock,
  RouteConfig,
} from "../../globals";
import classNames from "../../utils/classNames";

import Hamburger from "./Hamburger/hamburger";
import HeaderLogo from "./Logo/logo";
import { Navbar } from "./Navbar/navbar";
import { Sidebar } from "./Sidebar/sidebar";

interface HeaderWrapperProps extends BaseElementProps {
  initHeight?: string;
  height?: string;
}
export const HeaderWrapper = styled.header<HeaderWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: var(--header-z-idx);
  height: ${({ initHeight }) => initHeight || "var(--header-height-initial)"};

  .headerVisible {
    transition: all 0.3s;
    visibility: visible;
    [id*="Logo"] {
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }
    &#HeaderInner > .SideBar {
      top: var(--header-height-initial);
      transition: all 0.3s;
    }
  }
  .headerHidden {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.3s;
  }
  .headerFinal {
    height: ${({ height }) => height || "var(--header-height)"};
    background-color: var(--bg-secondary);
    &#HeaderInner {
      box-shadow: var(--card-boxshadow);
      .SideBar {
        top: var(--header-height);
      }
    }
    .NavScroll,
    .NavRoute {
      color: var(--header-nav-item-text-color-dark);
    }
    #Hamburger {
      color: var(--header-hamburger-bg);
    }
    transition: all 0.3s;
  }
`;

export const HeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background: var( --bg-secondary); */
  /* box-shadow: var(--card-boxshadow); */
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0 1rem;
  }
`;

/**
 * HeaderProps
  @key children: React.ReactNode;
  @key className: string;
 */
export interface HeaderProps extends HeaderConfig {
  children: React.ReactNode;
  className: string;
  navItems: RouteConfig[];
  cta: CtaConfig;
  hoverEffects: {
    textColor: string;
    backgroundColor: string;
  };
}
export const Header: React.FC<HeaderProps> = ({
  toggle = () => {},
  logoSrcSmall = "",
  logoSrcMedium = "",
  logoSrcLarge = "",
  logoSrcSmallDark = "",
  logoSrcMediumDark = "",
  logoSrcLargeDark = "",
  show = true,
  showSub = false,
  transition = false,
  navItems,
  height = "var(--header-height)",
  initHeight = "var(--header-height-initial)",
  cta,
  socials,
  hoverEffects,
}: HeaderProps) => {
  const [makeVisible, setMakeVisible] = useState<boolean>(true);
  const [makeSubVisible, setMakeSubVisible] = useState<boolean>(false);
  const [transitionFinal, setTransitionFinal] = useState(false);

  useEffect(() => {
    setMakeVisible(show);
  }, [show]);

  useEffect(() => {
    setMakeSubVisible(showSub);
  }, [showSub]);

  useEffect(() => {
    setTransitionFinal(transition);
  }, [transition]);

  return (
    <HeaderWrapper
      id="HeaderWrapper"
      role="banner"
      className={classNames(
        makeVisible
          ? transitionFinal === true
            ? "headerFinal"
            : "headerVisible"
          : "headerHidden"
      )}
      initHeight={initHeight}
      height={height}
    >
      <HeaderInner
        id={"HeaderInner"}
        className={classNames(
          makeVisible
            ? transitionFinal === true
              ? "headerFinal"
              : "headerVisible"
            : "headerHidden"
        )}
      >
        <Navbar
          className={"Navbar"}
          navItems={navItems}
          cta={cta}
          socials={socials}
          hoverEffects={hoverEffects}
        >
          <Hamburger id={"Hamburger"} toggle={toggle} />
          <HeaderLogo
            id={"HeaderLogo"}
            to="/"
            useDark={transitionFinal === true}
            imgSrcSmall={logoSrcSmall}
            imgSrcMedium={logoSrcMedium}
            imgSrcLarge={logoSrcLarge}
            imgSrcSmallDark={logoSrcSmallDark}
            imgSrcMediumDark={logoSrcMediumDark}
            imgSrcLargeDark={logoSrcLargeDark}
          />
        </Navbar>
        <Sidebar
          className={"SideBar"}
          navItems={navItems}
          cta={cta}
          socials={socials}
          hoverEffects={hoverEffects}
          headerHeight={height}
          isOpen={makeSubVisible}
          toggle={toggle}
        />
      </HeaderInner>
    </HeaderWrapper>
  );
};

export default Header;
