import React from "react";
import styled from "styled-components";

import { BaseElementProps, CtaConfig, HeaderConfig, IconBlock, RouteConfig, TextPosition } from "../../../globals";
import Cta from "../../Cta/cta";
import SubNav from "./sideNav";

interface SideBarWrapperProps extends BaseElementProps {
  isOpen: boolean;
  headerHeight?: string;
}
export const SideBarWrapper = styled.div<SideBarWrapperProps>`
  position: absolute;
  flex-direction: row;
  left: 0;
  top: ${({ headerHeight }) => headerHeight || "var(--header-height-initial)"};
  width: 100%;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  box-shadow: var(--card-boxshadow);

  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;
export const SideBarInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  background: var( --bg-secondary);

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
export const SideNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  /* padding: 0 45px; */
  cursor: pointer;
  white-space: normal;

  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;
export const SideBarTop = styled.ul`
  list-style: none;
  justify-content: space-evenly;
  width: 100%;

  @media only screen and (min-width: 768px) {
    list-style: none;
    padding: 0;
    min-width: 200px;
    height: 100%;
    /* border: 3px solid red; */
  }
`;

export interface SidebarProps extends HeaderConfig {
  className: string;
  toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  headerHeight?: string;
  isOpen: boolean;
  navItems: RouteConfig[];
  cta?: CtaConfig;
  socials?: IconBlock[];
  hoverEffects?: {
    textColor?: string;
    backgroundColor?: string;
  };
}
export const Sidebar: React.FC<SidebarProps> = ({
  className,
  isOpen,
  toggle,
  headerHeight,
  navItems,
  cta,
  socials,
  hoverEffects,
}: SidebarProps) => {
  return (
    <SideBarWrapper
      className={className}
      isOpen={isOpen}
      headerHeight={headerHeight}
    >
      <SideBarInner className="SideBarInner">
        <SideNav>
          <SideBarTop>
            {navItems && (
              <SubNav
                hoverEffects={hoverEffects}
                navItems={navItems}
                toggle={toggle}
              />
            )}
          </SideBarTop>
        </SideNav>
        <hr />
        {cta && (
          <li
            className={"NavItem"}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              backgroundColor: "transparent",
              margin: "1rem 0rem 1rem",
            }}
          >
            <Cta
              type={cta.type}
              to={cta.to}
              position={cta.position}
              label={cta.label}
              target={cta.target}
              rel={cta.rel}
            />
          </li>
        )}
      </SideBarInner>
    </SideBarWrapper>
  );
};
