import styled from "styled-components";
import { ROUTES, RouteConfig, PageProps, HeroConfig, CtaTypes, HeroType } from "../globals";
import { PageRoot } from "../pages/pageElements";
import { TEEN_TITANS, THE_JUSTICE_LEAGUE } from "./heroes";

import LandingPage, { LandingPageProps } from "../pages/landing";
// import HomePage, { HomePageProps } from "../pages/home";
// import LoginPage, { LoginPageProps } from "../pages/login";
// import SignUpPage, { SignUpPageProps } from "../pages/sign-up";
// import PrivacyPage, { PrivacyPageProps } from '../Pages/privacy';
// import FaqPage, { FaqPageProps } from '../Pages/faq';
// import TosPage, { TosPageProps } from '../Pages/tos';
// import SupportPage, { SupportPageProps } from '../Pages/support';
// import FeedbackPage, { FeedbackPageProps } from '../Pages/feedback';
// import PageNotFound, { PageNotFoundProps } from '../Pages/page-not-found';

// export const LandingPageRoot = styled(PageRoot)``;
// export interface LandingPageProps extends PageProps {
//   id: string;
//   children?: React.ReactNode;
//   heroConfig?: HeroProps;
//   [key: string]: any;
// }
// export function LandingPage({ children , id, heroConfig}: LandingPageProps) {
//   return (
//     <>
//       {children ? (
//         <LandingPageRoot className="LandingPageRoot" id={id}>
//           {children}
//         </LandingPageRoot>
//       ) : (
//         <LandingPageRoot className="LandingPageRoot" id={id}>
//           <Hero />
//         </LandingPageRoot>
//       )}
//     </>
//   );
// }

// export const HomePageRoot = styled(PageRoot)``;
// export interface HomePageProps extends PageProps {
//   id: string;
//   children?: React.ReactNode;
//   heroConfig?: HeroProps;
//   [key: string]: any;
// }
// export function HomePage({ children, id, heroConfig }: HomePageProps) {
//   return (
//     <>
//       {children ? (
//         <HomePageRoot className="HomePageRoot" id={id}>
//           {children}
//         </HomePageRoot>
//       ) : (
//         <HomePageRoot className="HomePageRoot" id={id}>
//           <Hero />
//         </HomePageRoot>
//       )}
//     </>
//   );
// }

// export const FeaturesPageRoot = styled(PageRoot)``;
// export interface FeaturesPageProps extends PageProps {
//   children?: React.ReactNode;
//   id: string;
//   [key: string]: any;
// }
// export function FeaturesPage({ children, id }: FeaturesPageProps) {
//   return (
//     <FeaturesPageRoot className="FeaturesPageRoot" id={id}>
//       TheFeaturesPage
//     </FeaturesPageRoot>
//   );
// }

// export const ProductsPageRoot = styled(PageRoot)``;
// export interface ProductsPageProps extends PageProps {
//   children?: React.ReactNode;
//   id: string;
//   [key: string]: any;
// }
// export function ProductsPage({ children, id }: ProductsPageProps) {
//   return (
//     <ProductsPageRoot className="ProductsPageRoot" id={id}>
//       TheProductsPage
//     </ProductsPageRoot>
//   );
// }

export const LoginPageRoot = styled(PageRoot)``;
export interface LoginPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function LoginPage({ children, id }: LoginPageProps) {
  return (
    <LoginPageRoot className="LoginPageRoot" id={id}>
      TheLoginPage
    </LoginPageRoot>
  );
}

export const SignUpPageRoot = styled(PageRoot)``;
export interface SignUpPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function SignUpPage({ children, id }: SignUpPageProps) {
  return (
    <SignUpPageRoot className="SignUpPageRoot" id={id}>
      TheSignUpPage
    </SignUpPageRoot>
  );
}

export const PrivacyPageRoot = styled(PageRoot)``;
export interface PrivacyPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function PrivacyPage({ children, id }: PrivacyPageProps) {
  return (
    <PrivacyPageRoot className="PrivacyPageRoot" id={id}>
      ThePrivacyPage
    </PrivacyPageRoot>
  );
}

export const FaqPageRoot = styled(PageRoot)``;
export interface FaqPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function FaqPage({ children, id }: FaqPageProps) {
  return (
    <FaqPageRoot className="FaqPageRoot" id={id}>
      TheFaqPage
    </FaqPageRoot>
  );
}

export const TosPageRoot = styled(PageRoot)``;
export interface TosPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function TosPage({ children, id }: TosPageProps) {
  return (
    <TosPageRoot className="TosPageRoot" id={id}>
      TheTosPage
    </TosPageRoot>
  );
}

export const SupportPageRoot = styled(PageRoot)``;
export interface SupportPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function SupportPage({ children, id }: SupportPageProps) {
  return (
    <SupportPageRoot className="SupportPageRoot" id={id}>
      TheSupportPage
    </SupportPageRoot>
  );
}

export const FeedbackPageRoot = styled(PageRoot)``;
export interface FeedbackPageProps extends PageProps {
  children?: React.ReactNode;
  id: string;
  [key: string]: any;
}
export function FeedbackPage({ children, id }: FeedbackPageProps) {
  return (
    <FeedbackPageRoot className="FeedbackPageRoot" id={id}>
      TheFeedbackPage
    </FeedbackPageRoot>
  );
}

export const PageNotFoundRoot = styled(PageRoot)``;
export interface PageNotFoundProps extends PageProps {
  [key: string]: any;
}
export function PageNotFound({ children, id }: PageNotFoundProps) {
  return (
    <PageNotFoundRoot className="PageNotFoundRoot" id={id}>
      ThePageNotFound
    </PageNotFoundRoot>
  );
}

export const ROUTE_CONFIGS: RouteConfig[] = [
  {
    path: ROUTES.LANDING,
    name: "Landing",
    component: LandingPage,
    componentProps: {
      id: "landing",
      path: ROUTES.LANDING,
      // introductionHeight: "",
      introductionConfig: {
        heroConfig: THE_JUSTICE_LEAGUE[0],
        title: "Combinatronix Labs",
        // subtitle: "Business Software Solutions & Application Service Provider",
        body: "Business Software Solutions & Application Service Provider",
        // bodyFontColor: "black",
        bgColor: "green",
      },
      // featuresHeight: "40vh",
      featuresConfig: {
        cardConfigs: [
          {
            image: {
              src: "//images.ctfassets.net/rz1oowkt5gyp/2ozLp25HsHeANpiNqAwzGI/d60a017d2f214c28f774b35d03499782/tag.svg",
              alt: "",
              width: "103",
              height: "167",
              loading: "lazy",
            },
            caption: undefined,
            title: "Simple Solutions",
            subtitle: undefined,
            body: "Our solutions are easy to use right out of the box and can be configured for all.",
            // ctas: [
            //   // {
            //   //   type: CtaTypes.Anchor,
            //   //   title: "Primary",
            //   //   to: "#",
            //   //   target: "_blank",
            //   //   label: "Learn More",
            //   //   bgColor: "",
            //   //   rel: "noopener",
            //   // },
            // ],
          },
          {
            image: {
              src: "//images.ctfassets.net/rz1oowkt5gyp/6rRWTcHzPKupQ3ZDqBaxu6/aecd045f4b31a92288a85bdb4a9dfe2c/compass.svg",
              alt: "",
              width: "134",
              height: "161",
              loading: "lazy",
            },
            caption: undefined,
            title: "Business Analytics",
            subtitle: undefined,
            body: "Improve your processes by understanding the way your business operates across several data points.",
            // ctas: [
            //   // {
            //   //   type: CtaTypes.Anchor,
            //   //   title: "Primary",
            //   //   to: "#",
            //   //   target: "_blank",
            //   //   label: "Learn More",
            //   //   bgColor: "",
            //   //   rel: "noopener",
            //   // },
            // ],
          },
          {
            image: {
              src: "//images.ctfassets.net/rz1oowkt5gyp/5AE4nXLOennRxmmUMcgMLM/747e96bdd16cf4113e4ef867bd85fd29/solutions.svg",
              alt: "",
              width: "146",
              height: "138",
              loading: "lazy",
            },
            caption: undefined,
            title: "Secure Your business",
            subtitle: undefined,
            body: "Keep information on a need-to-know basis and protect all of your data safe from everyone else.",
            // ctas: [
            //   // {
            //   //   type: CtaTypes.Button,
            //   //   title: "Primary",
            //   //   to: "#",
            //   //   target: "_blank",
            //   //   label: "Learn More",
            //   //   bgColor: "",
            //   //   rel: "noopener",
            //   // },
            // ],
          },
        ],
      },
      maidenHeight: "100vh",
      maidenConfig: {
        cardConfig: {
          imageStart: true,
          useShadow: true,
          bgImage: {
            src: undefined,
            grad: {
              from: "bottom",
              to: "left",
              fromColor: "rgb(0, 89, 255)",
              toColor: "rgb(190, 87, 0)",
            },
            width: "",
            height: "",
            bgPosition: "center",
            bgRepeat: "no-repeat",
            bgSize: "contain",
            filter: "blur(1rem)",
          },
          image: {
            // src: "/img/maiden-logo.png",
            src: "/img/maiden-icon-white-493-by-512.svg",
            alt: "maiden logo",
            width: "180",
            height: "180",
            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
          },
          // iframe: {
          //   src: "https://projectmaiden.org/",
          //   width: "100%",
          //   height: "100%",
          //   frameBorder: "0",
          //   sandbox: "allow-scripts allow-same-origin",
          //   referrerPolicy: "no-referrer",
          //   maxHeight: "500px",
          // },
          caption: undefined,
          captionFontColor: "var(--text-color)",
          title: "Project Maiden",
          titleFontColor: "var(--text-color)",
          subtitle: "A restaurant operations management tool",
          subtitleFontColor: "var(--text-color)",
          body: `Layout routine tasks throughout the day or throughout the week, log the statuses of tasks, and display all the information about those tasks.`,
          bodyFontColor: "var(--text-color)",
          ctas: [
            {
              type: CtaTypes.Anchor,
              to: "https://projectmaiden.org/",
              target: "_blank",
              title: "App Store",
              label: "Visit Site",
              bgColor: "",
              rel: "noopener",
              width: "auto",
            },
            {
              type: CtaTypes.Apple,
              to: "https://apps.apple.com/us/app/project-maiden/id1573166507",
              target: "_blank",
              title: "App Store",
              label: "Download on the App Store",
              bgColor: "",
              rel: "noopener",
              image: {
                src: "/img/Download-on-the-App-Store/Download-on-the-App-Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg",
                alt: "App Store",
                width: "290px",
                height: "50px",
                maxWidth: "140px",
              },
            },
            {
              type: CtaTypes.Google,
              to: "https://play.google.com/store/apps/details?id=com.combinatronixlabs.maiden_flutter.prod",
              target: "_blank",
              title: "App Store",
              label: "Get it on Google Play",
              bgColor: "",
              rel: "noopener",
              image: {
                src: "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
                alt: "App Store",
                width: "646",
                height: "250",
                maxWidth: "165px",
              },
            },
          ],
        } as HeroConfig,
      },
      tdaHeight: "100vh",
      tdaConfig: {
        cardConfig: {
          bgColor: "#556B2Faf",
          imageStart: false,
          bgImage: {
            src: undefined,
            grad: {
              from: "bottom",
              to: "left",
              fromColor: "#556B2Faf",
              toColor: "#556B2F",
            },
            width: "1000",
            height: "1000",
            bgPosition: "center",
            bgRepeat: "no-repeat",
            bgSize: "contain",
            filter: "blur(0.5rem)",
          },
          image: {
            // src: "/img/tda-logo.png",
            src: "/img/tda-logo-white.svg",
            alt: "TDA Online logo",
            width: "785",
            height: "1000",
            // bgColor: "#556B2Faf",
            bgColor: "transparent",
            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
          },
          // iframe: {
          //   src: "https://tda.combinatronixlabs.org",
          //   width: "100%",
          //   height: "100%",
          //   frameBorder: "1",
          //   sandbox: "allow-scripts allow-same-origin",
          //   referrerPolicy: "no-referrer",
          //   maxHeight: "500px",
          // },
          caption: undefined,
          captionFontColor: "var(--text-color)",
          title: "TDA Online",
          titleFontColor: "var(--text-color)",
          subtitle: undefined,
          subtitleFontColor: "var(--text-color)",
          body: "We have developed this tool as a simple and easy way for students and professionals to analyze their data.",
          bodyFontColor: "var(--text-color)",
          ctas: [
            {
              type: CtaTypes.Anchor,
              to: "https://tda.combinatronixlabs.org",
              target: "_blank",
              label: "Learn More",
              bgColor: "",
              width: "auto",
              rel: undefined,
            },
          ],
        } as HeroConfig,
      },
      cronosHeight: "100vh",
      cronosConfig: {
        cardConfig: {
          imageStart: true,
          bgImage: {
            src: undefined,
            grad: {
              from: "bottom",
              to: "left",
              fromColor: "rgb(0, 80, 80)",
              toColor: "rgba(0, 80, 80, 0.75)",
            },
            width: "",
            height: "",
            bgPosition: "center",
            bgRepeat: "no-repeat",
            bgSize: "contain",
            filter: "blur(1rem)",
          },
          image: {
            // src: "/img/cronosio-logo.png",
            src: "/img/cronosio-icon-white-1000-by-1000.svg",
            alt: "Cronos IO Logo",
            width: "1024",
            height: "1024",
            filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
          },
          // iframe: {
          //   src: "https://projectmaiden.org/",
          //   width: "100%",
          //   height: "100%",
          //   frameBorder: "0",
          //   sandbox: "allow-scripts allow-same-origin",
          //   referrerPolicy: "no-referrer",
          //   maxHeight: "500px",
          // },
          caption: undefined,
          captionFontColor: "var(--text-color)",
          title: "Cronos IO",
          titleFontColor: "var(--text-color)",
          subtitle: "Track project info, clients, and shifts",
          subtitleFontColor: "var(--text-color)",
          body: `Keep your projects organized. Organize the workload 
          into manageable 'shifts.' View your project information on any device.`,
          bodyFontColor: "var(--text-color)",
          ctas: [
            {
              type: CtaTypes.Apple,
              to: "https://apps.apple.com/us/app/cronos-io/id1641595777",
              target: "_blank",
              title: "App Store",
              label: "Download on the App Store",
              bgColor: "",
              rel: "noopener",
              image: {
                src: "/img/Download-on-the-App-Store/Download-on-the-App-Store/US/Download_on_App_Store/Black_lockup/SVG/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg",
                alt: "App Store",
                width: "290px",
                height: "50px",
                maxWidth: "140px",
              },
            },
            {
              type: CtaTypes.Google,
              to: "https://play.google.com/store/apps/details?id=com.combinatronixlabs.timepunch.time_punch",
              target: "_blank",
              title: "App Store",
              label: "Get it on Google Play",
              bgColor: "",
              rel: "noopener",
              image: {
                src: "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
                alt: "App Store",
                width: "646",
                height: "250",
                maxWidth: "165px",
              },
            },
          ],
        } as HeroConfig,
      },
      logHeight: "100vh",
      logConfig: {
        heroConfig: {
          image: undefined,
          caption: undefined,
          title: "",
          subtitle: undefined,
          body: "",
          ctas: [
            {
              type: CtaTypes.Anchor,
              to: "https://projectmaiden.org",
              target: undefined,
              label: "Learn More",
              bgColor: "",
              rel: undefined,
            },
            {
              type: CtaTypes.Anchor,
              title: undefined,
              to: "",
              target: undefined,
              label: "",
              bgColor: "",
              rel: undefined,
            },
            {
              type: CtaTypes.Anchor,
              title: undefined,
              to: "",
              target: undefined,
              label: "",
              bgColor: "",
              rel: undefined,
            },
          ],
        } as HeroConfig,
      },
      statisticsHeight: "40vh",
      statisticsConfig: {
        strength: 250,
        heroConfigs: [TEEN_TITANS[0]],
      },
      contactUsHeight: "100vh",
      contactUsConfig: {
        cardConfig: {
          image: undefined,
          caption: undefined,
          captionFontColor: "var(--text-color)",
          title:
            "Interested in learning how we can help answer your most important business questions?",
          titleFontColor: "var(--text-color)",
          subtitle: undefined,
          subtitleFontColor: "var(--text-color)",
          body: "Drop us a note, and we'll get back to you as soon as possible.",
          bodyFontColor: "var(--text-color)",
          ctas: undefined,
        } as HeroConfig,
      },
    } as LandingPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: false,
  },
  // {
  //   path: ROUTES.HOME,
  //   name: "Home",
  //   component: HomePage,
  //   componentProps: {
  //     id: "home",
  //     path: ROUTES.HOME,
  //     introductionHeight: "100vh",
  //     introductionConfig: {
  //       heroConfig: THE_JUSTICE_LEAGUE[0],
  //     },
  //   } as HomePageProps,
  //   isPrivate: true,
  //   isScroll: false,
  //   inNav: true,
  //   exact: true,
  // },
  {
    path: `${ROUTES.LANDING}#features`,
    name: "Features",
    // component: FeaturesPage,
    // componentProps: { id: "" } as FeaturesPageProps,
    isPrivate: false,
    isScroll: true,
    inNav: true,
    exact: false,
  },
  {
    path: `${ROUTES.LANDING}#products`,
    name: "Products",
    // component: ProductsPage,
    // componentProps: { id: "" } as ProductsPageProps,
    isPrivate: false,
    isScroll: true,
    inNav: true,
    exact: false,
  },
  {
    path: ROUTES.LOGIN,
    name: "Login",
    component: LoginPage,
    componentProps: { id: "" } as LoginPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.SIGN_UP,
    name: "Sign-Up",
    component: SignUpPage,
    componentProps: { id: "" } as SignUpPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.PRIVACY,
    name: "Privacy",
    component: PrivacyPage,
    componentProps: { id: "" } as PrivacyPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.FAQ,
    name: "Frequently Asked Questions",
    component: FaqPage,
    componentProps: { id: "" } as FaqPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.TOS,
    name: "Terms Of Service",
    component: TosPage,
    componentProps: { id: "" } as TosPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.SUPPORT,
    name: "Support",
    component: SupportPage,
    componentProps: { id: "" } as SupportPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    path: ROUTES.FEEBACK,
    name: "Feedback",
    component: FeedbackPage,
    componentProps: { id: "" } as FeedbackPageProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: true,
  },
  {
    // KEEP THIS LAST
    path: ROUTES.PAGE_NOT_FOUND,
    name: "Page Not found",
    component: PageNotFound,
    componentProps: { id: "" } as PageNotFoundProps,
    isPrivate: false,
    isScroll: false,
    inNav: false,
    exact: false,
  },
];
