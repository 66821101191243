import styled from "styled-components";

export const PageRoot = styled.main`
  position: relative;
  /* top: var(--header-height); */
  left: 0;
  right: 0;
  width: 100%;
  /* height: 100%; */
  /* height: 100vh; */
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HomePageRoot = styled(PageRoot)``;
export const LandingPageRoot = styled(PageRoot)`
  .IntroductionSection {
  }
  .FeaturesSection {
  }
  .MaidenSection {
    --hero-height: 115vh;
    height: var(--hero-height);
  }
  .TdaSection {
    --hero-height: 85vh;
    height: var(--hero-height);
  }
  .CronosSection {
  }
  .StatisticsSection {
  }
  .ContactUsSection {
    --hero-height: 160vh;
    height: var(--hero-height);
    .ContentCardInner {
      padding: 0rem 0rem 0rem 0rem;
      flex-direction: column-reverse;
      .LeftColumn {
        justify-content: flex-start;
        color: var(--text-color);
      }
      .RightColumn {
        justify-content: flex-end;

        h1,
        p {
          color: var(--text-color);
          font-family: sans-serif;
          font-style: normal;
          font-weight: 700 !important;
          letter-spacing: 0.5px;
          line-height: 1.2;
          text-shadow: black 2px 0px 5px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .ContentCardInner {
        padding: 5rem 5rem 5rem 5rem;
        flex-direction: row-reverse;
        .LeftColumn {
          justify-content: center;
        }
        .RightColumn {
          justify-content: center;
          h1,
          p {
            text-align: center;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
    }
  }
`;
