import styled from "styled-components";
import { BaseElementProps } from "../../globals";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const CtaButton = styled.button<BaseElementProps>`
  height: ${({ height }) => height || "100%"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 15px;
  /* border-radius: 5px; */
  outline: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  color: ${({ fontColor }) => fontColor || "var(--text-color-dark)"};
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;

  background-color: var(--accent);
  transition: ease background-color 250ms;
  /* box-shadow: 0px 2px 2px lightgray; */
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &:hover {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--button-bg-color)";
    }};

    color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.textColor || "var(--button-text-color-light)";
    }};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const CtaAnchor = styled.a<BaseElementProps>`
  display: inline-block;
  width: fit-content;
  height: ${({ height }) => height || "100%"};
  padding: 12px 24px;
  border-radius: 5px;
  background-color: ${({ bgColor }) => bgColor || "#3C3C3C"};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  color: ${({ fontColor }) => fontColor || "var(--text-color)"};
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;

  /* background-color: var(--accent); */
  transition: ease background-color 250ms;
  /* box-shadow: 0px 2px 2px lightgray; */
  & > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &:hover {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};

    color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.textColor || "var(--button-text-color-light)";
    }};
  }
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const CtaRouter = styled(LinkR)<BaseElementProps>`
  display: inline-block;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 3px;
  background-color: ${({ bgColor }) => bgColor || "#3C3C3C"};
  color: ${({ fontColor }) => fontColor || "var(--text-color)"};
  cursor: pointer;
  text-align: center;
  fill: var(--text-color);
  font-size: 1rem;
  font-weight: 500;
  box-shadow: var(--box-shadow);
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1.5;
`;

export const CtaScroll = styled(LinkS)<BaseElementProps>`
  display: inline-block;
  width: fit-content;
  padding: 12px 24px;
  border-radius: 3px;
  background-color: ${({ bgColor }) => bgColor || "#3C3C3C"};
  color: ${({ fontColor }) => fontColor || "var(--text-color)"};
  cursor: pointer;
  text-align: center;
  fill: var(--text-color);
  font-size: 1rem;
  font-weight: 500;
  box-shadow: var(--box-shadow);
  text-decoration: none;
  box-sizing: border-box;
  line-height: 1.5;
`;
