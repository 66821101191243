import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroType, HeroConfig } from "../../../../globals";
import { toggleCaptchaBadge } from "../../../../utils/toggleCaptchaBadge";
import ContactUsForm from "./contactUsForm";

export interface ContactUsSectionProps extends HeroProps {
  height?: string;
  cardConfig?: HeroConfig;
  [key: string]: any;
}
export default function ContactUsSection({
  height,
  cardConfig,
  ...props
}: ContactUsSectionProps) {

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    console.log({
      height,
      cardConfig,
      props,
    });
  }, []);

  useEffect(() => {
    toggleCaptchaBadge(inView);
    return () => toggleCaptchaBadge(false);
  }, [inView]);
  
  return (
    <Hero
      type={HeroType.Content}
      height={height}
      subHeros={[cardConfig]}
      {...props}
    >
      <ContactUsForm ref={ref}/>
    </Hero>
  );
}
