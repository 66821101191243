import { IconType } from "react-icons";
import styled from "styled-components";
import { BaseSocialLink, HeaderConfig, IconBlock, BaseElementProps } from "../../../globals";

export const SocialContainer = styled.div`
  display: table;
`;
export const SocialInner = styled.div`
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 0 16px;
`;

export interface SocialNavProps extends HeaderConfig {
  hoverEffects?: { textColor?: string; backgroundColor?: string };
  socials: IconBlock[];
}
export const SocialNav: React.FC<SocialNavProps> = ({
  hoverEffects,
  socials,
}: SocialNavProps) => {
  return (
    <SocialContainer className="SocialContainer">
      <SocialInner className="SocialInner">
        {socials.map((it, idx) => {
          const navProps: SocialNavItemProps = {
            key: "social" + idx,
            className: "SocialNavItemProps",
            src: it.src,
            linkTarget: "_blank",
            SocialIcon: it.icon,
            iconSize: 24,
            iconColor: "#fff",
            hovercolor: hoverEffects?.backgroundColor,
          };
          return <SocialNavItem {...navProps} />;
        })}
      </SocialInner>
    </SocialContainer>
  );
};

export const NavSocialLink = styled(BaseSocialLink)``;
export const SocialIconContainer = styled.div<BaseElementProps>`
  width: 51px;
  height: 51px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  border: 2px solid var(--primary);
  border-radius: 50%;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  color: var(--text-color-dark);

  &:hover {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};
  }
  &:active {
    background-color: ${({ hoverEffects }) => {
      if (typeof hoverEffects === "string") {
        return hoverEffects;
      }
      return hoverEffects?.backgroundColor || "var(--primary-dark)";
    }};
  }
`;

export interface SocialNavItemProps {
  className?: string;
  src: string;
  linkTarget: string;
  SocialIcon: IconType;
  iconSize: number;
  iconColor: string;
  hoverEffects?: { textColor?: string; backgroundColor?: string };
  [key: string]: any;
}
export const SocialNavItem: React.FC<SocialNavItemProps> = ({
  className,
  src,
  linkTarget,
  SocialIcon,
  iconSize,
  iconColor,
  hoverEffects,
}: SocialNavItemProps) => (
  <NavSocialLink itemProp="url" href={src} target={linkTarget}>
    <SocialIconContainer
      className={className}
      hoverEffects={{ backgroundColor: hoverEffects?.backgroundColor || "rgb(60, 60, 60)" }}
    >
      <SocialIcon size={iconSize} color={iconColor} />
    </SocialIconContainer>
  </NavSocialLink>
);
