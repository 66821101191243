import * as firebase from "@firebase/app";
import { Auth, getAuth, connectAuthEmulator } from "@firebase/auth";
import {
  Firestore,
  getFirestore,
  connectFirestoreEmulator,
} from "@firebase/firestore";
import {
  FirebaseStorage,
  getStorage,
  connectStorageEmulator,
} from "@firebase/storage";
import {
  Functions,
  getFunctions,
  HttpsCallable,
  httpsCallable,
  connectFunctionsEmulator,
} from "@firebase/functions";
import { InfoRequest } from "../../globals";

export default class FirebaseService {
  auth!: Auth;
  db!: Firestore;
  storage!: FirebaseStorage;
  functions!: Functions;
  private app!: firebase.FirebaseApp;
  private _config?: Record<string, any>;

  private pullConfig_ = () => {
    const {
      // REACT_APP_ENV,
      REACT_APP_FIREBASE_APIKEY,
      REACT_APP_FIREBASE_AUTHDOMAIN,
      REACT_APP_FIREBASE_PROJECTID,
      REACT_APP_FIREBASE_STORAGEBUCKET,
      REACT_APP_FIREBASE_MESSAGINGSENDERID,
      REACT_APP_FIREBASE_APPID,
      REACT_APP_FIREBASE_MEASUREMENTID,
    } = process.env;
    if (
      !REACT_APP_FIREBASE_PROJECTID &&
      !REACT_APP_FIREBASE_APPID &&
      // !NX_DATABASE_URL &&
      !REACT_APP_FIREBASE_STORAGEBUCKET &&
      // !NX_LOCATION_ID &&
      !REACT_APP_FIREBASE_APIKEY &&
      !REACT_APP_FIREBASE_AUTHDOMAIN &&
      !REACT_APP_FIREBASE_MESSAGINGSENDERID
    )
      throw new Error("Invalid Environment");
    return {
      projectId: REACT_APP_FIREBASE_PROJECTID,
      appId: REACT_APP_FIREBASE_APPID,
      // databaseURL: NX_DATABASE_URL,
      storageBucket: REACT_APP_FIREBASE_STORAGEBUCKET,
      // locationId: NX_LOCATION_ID,
      apiKey: REACT_APP_FIREBASE_APIKEY,
      authDomain: REACT_APP_FIREBASE_AUTHDOMAIN,
      messagingSenderId: REACT_APP_FIREBASE_MESSAGINGSENDERID,
      measurementId: REACT_APP_FIREBASE_MEASUREMENTID,
    };
  };

  constructor() {
    this._config = this.pullConfig_();
  }

  init = () => {
    try {
      this.app = firebase.initializeApp(this._config || this.pullConfig_());
    } catch (error) {
      console.log("[FirebaseService.init] error:", { error });
      throw new Error("Unable to initialize Firebase");
    }

    this.auth = getAuth(this.app);
    this.db = getFirestore(this.app);
    this.storage = getStorage(this.app);
    this.functions = getFunctions(this.app);

    if (process.env.NODE_ENV === "development") {
      connectAuthEmulator(this.auth, "http://localhost:9099");
      if (window.location.hostname === "localhost") {
        connectStorageEmulator(this.storage, "localhost", 9199);
        connectFunctionsEmulator(this.functions, "localhost", 5001);
        connectFirestoreEmulator(this.db, "localhost", 8080);
      }
    }
    return this;
  };

  doRequestInformation = async ({
    email,
    name,
    phone,
    company,
    reason,
    message,
    reCaptchaToken,
  }: InfoRequest): Promise<any> => {
    const requestInfo: HttpsCallable = httpsCallable(
      this.functions,
      "processInfoRequest"
    );
    return await requestInfo({
      email,
      name,
      phone,
      company,
      reason,
      message,
      reCaptchaToken,
    });
  };
}
