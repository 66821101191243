import React from "react";
import styled from "styled-components";
import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroConfig, HeroType } from "../../../../globals";
import Statistic from "./statistic";

const StatisticsContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  align-items: baseline;
  width: 100%;
  margin: 0 auto;
  
  padding: 0px 0px 0px 0px;
  @media only screen and (min-width: 768px) {
    padding: 104px 0px 104px 0px;
  }
`;
const StatisticsInner = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;
export interface StatisticsSectionProps extends HeroProps {
  height?: string;
  strength?: string | number;
  heroConfigs?: HeroConfig[];
  [key: string]: any;
}
export default function StatisticsSection({
  height,
  strength,
  heroConfigs,
  ...props
}: StatisticsSectionProps) {
  // console.log("[StatisticsSection]", {
  //   height,
  //   strength,
  //   heroConfigs,
  //   props,
  // });
  return (
    <Hero
      type={HeroType.Parallex}
      height={height}
      strength={strength}
      subHeros={heroConfigs}
      {...props}
    >
      <StatisticsContainer className="StatisticsContainer">
        <StatisticsInner className="StatisticsInner">
          <Statistic
            {...{ label: "Satisfied Clients", statistic: 21, duration: 2000 }}
          />
          <Statistic
            {...{ label: "Completed Projects", statistic: 25, duration: 2000 }}
          />
          <Statistic
            {...{ label: "Referred Clients", statistic: 15, duration: 2000 }}
          />
          <Statistic
            {...{ label: "Man Hours Saved", statistic: 25000, duration: 2000, shouldAddPlus: true }}
          />
        </StatisticsInner>
      </StatisticsContainer>
    </Hero>
  );
}
