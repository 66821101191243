import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider as GlobalThemeProvider } from "styled-components";

export const ThemesContext =
  React.createContext<(boolean | typeof Themes | Themes | (() => void))[]>([]);
export const useTheme = () => useContext(ThemesContext);

enum Themes {
  light = "Light",
  dark = "Dark",
}

export const lightTheme = {
  body: "#E2E2E2",
  text: "#363537",
  toggleBorder: "#FFF",
  gradient:
    "linear-gradient(to top right, var(--complete-selected), var(--unvisited-selected))",
};

export const darkTheme = {
  body: "#363537",
  text: "#FAFAFA",
  toggleBorder: "#6B8096",
  gradient: "linear-gradient(to top right, var(--bg-dark), var( --bg-gradient)",
};

export const ThemeProvider = ({ children }: { children: any }) => {
  const [theme, setTheme] = useState(Themes.light);
  const [componentMounted, setComponentMounted] = useState(false);

  const setMode = (mode: Themes) => {
    window.localStorage.setItem("theme", mode);
    setTheme(mode);
  };

  const toggleTheme = () => {
    if (theme === Themes.light) {
      setMode(Themes.dark);
    } else {
      setMode(Themes.light);
    }
  };

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme");
    if (localTheme) {
      if (localTheme === Themes.light) setTheme(Themes.light);
      else if (localTheme === Themes.dark) setTheme(Themes.dark);
    } else {
      setMode(Themes.light);
    }
    setComponentMounted(true);
  }, []);

  const globalTheme = theme === Themes.light ? lightTheme : darkTheme;

  return (
    <GlobalThemeProvider theme={globalTheme}>
      <ThemesContext.Provider value={[theme, toggleTheme, Themes, componentMounted]}>
      {children}
      </ThemesContext.Provider>
    </GlobalThemeProvider>
  );
};
