import React from "react";
import styled from "styled-components";
import CountUp, { useCountUp } from "react-countup";
import { useInView } from "react-intersection-observer";

const StatisticContentContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;

  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  @media only screen and (min-width: 1024px) {
    width: 25%;
  }
`;

const StatisticContentInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: auto;
  max-width: 100%;
  @media only screen and (min-width: 1024px) {
    width: 100%;
  }
`;
const StatisticContent = styled.div`
  display: block;
  padding: 20px;
  opacity: 1;
  text-align: center;
`;
const TheStatic = styled.span`
  height: 1em;
  display: inline-block;
  color: white; // var(--text-color);
  font-size: 72px;
  font-weight: 400;
  font-family: "Scope One", sans-serif;
  line-height: 1em;
  @media only screen and (min-width: 1024px) {
    text-align: center;
  }
`;
const TheLabel = styled.p`
  color: white; // var(--text-color);
  font-family: "Cabin Condensed", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
  margin: 7px 0 0;
  letter-spacing: 2px;
`;
interface StatisticProps {
  label: string;
  statistic: number;
  duration: number;
  shouldAddPlus?: boolean;
}

export default function Statistic({
  label,
  statistic,
  duration = 2000,
  shouldAddPlus = false,
}: StatisticProps) {
  const { ref, inView } = useInView();
  return (
    <StatisticContentContainer className="StatisticContentContainer">
      <StatisticContentInner className="StatisticContentInner">
        <StatisticContent className="StatisticContent">
          <CountUp
            start={inView ? 0 : undefined}
            end={statistic}
            duration={duration / 1000}
            delay={0}
            suffix={shouldAddPlus ? '+' : ''}
          >
            {({ countUpRef }) => (
              <TheStatic ref={ref} className="TheStatic">
                <span ref={countUpRef} />
              </TheStatic>
            )}
          </CountUp>
          <TheLabel className="TheLabel">{label}</TheLabel>
        </StatisticContent>
      </StatisticContentInner>
    </StatisticContentContainer>
  );
}
