import React from "react";
import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroConfig, HeroType } from "../../../../globals";
import styled from "styled-components";

// import { Link as LinkR } from 'react-router-dom'
// import { Link as LinkS } from 'react-scroll'
interface ContentCardContainerProps {
  height?: string;
  bgColor?: string;
  useShadow?: boolean;
}
export const ContentCardContainer = styled.section<ContentCardContainerProps>`
  scroll-margin-top: 72px;
  width: 100%;
  /* height: ${({ height }) => height || "fit-content"}; */
  background: ${({ bgColor }) => bgColor || "var(--primary-dark)"};
  box-shadow: ${({ useShadow }) =>
    useShadow ? "var(--card-boxshadow)" : "intial"};

  /* @media screen and (max-width: 768px) {
    height: fit-content;
  } */
`;

export const ContentCardInner = styled.div<{ imgStart?: boolean }>`
  width: 100%;
  /* height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center; */
  margin: 0px auto;
  padding: 0px 1rem 0px 1rem;

  @media screen and (min-width: 768px) {
    max-width: 720px;
    /* margin: 0px 0px 0px 0px; */
    /* padding: 110px 0px 110px 0px; */
  }
  @media screen and (min-width: 1024px) {
    max-width: 960px;
    /* margin-right: 0px auto; */
    /* padding: 110px 64px 110px 64px; */
  } ;
`;
export const ContentCardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: -1rem;
  margin-right: -1rem;
`;
export const ContentCardHorzBar = styled.div`
  padding: 1rem;
  position: relative;
  scroll-margin-top: 72px;
  text-align: center;
  width: 100%;
`;
export const ContentCardImage = styled.div`
  padding: 1rem;
  position: relative;
  scroll-margin-top: 72px;
  text-align: left;
  display: block;
  flex: 0 0 auto;
  width: 25%;

  & > img {
    margin-bottom: 0px;
    max-width: 100%;
    height: auto;
  }

  @media screen and (min-width: 768px) {
    /* max-width: 720px; */
  }
  @media screen and (min-width: 1024px) {
    display: block;
    flex: 0 0 auto;
    width: 8.33333%;
  } ;
`;
export const ContentCardContent = styled.div`
  padding: 1rem;
  position: relative;
  scroll-margin-top: 72px;
  text-align: left;
  display: block;
  flex: 0 0 auto;
  width: 83.3333%;

  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1024px) {
    display: block;
    flex: 0 0 auto;
    width: 25%;
  }
`;

export const ContentCardContentTextContainer = styled.div`
  font-size: 1rem;
  & > h3 {
    color: var(--text-color);
    font-family: "Charlie Display", sans-serif;
    font-weight: 500;
    margin-bottom: 0.5rem;
    margin-top: 0px;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  & > p {
    color: var(--text-color);
    margin-bottom: 1.5rem;
    margin-top: 0px;
  }
  @media screen and (min-width: 768px) {
    & > h3 {
      font-size: 1.5rem;
      line-height: 1.33333;
    }
  }
  @media screen and (min-width: 1024px) {
  }
`;
export const ContentCardContentCtaContainer = styled.div`
  display: inline-block;
  margin-bottom: 0px;
  & > a {
    user-select: none;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 0.3rem;
    cursor: pointer;
    display: inline-flex;
    transition: all 250ms ease-out 0s;
    background-color: rgb(0, 101, 255);
    border: 1px solid rgb(0, 101, 255);
    text-decoration: none;
    font-size: 1rem;
    padding: 0.7rem 1rem 0.8rem;
    color: rgb(255, 255, 255) !important;
  }
  @media screen and (min-width: 768px) {
  }
  @media screen and (min-width: 1024px) {
  }
`;
export interface FeaturesSectionProps extends HeroProps {
  type?: HeroType;
  height?: string;
  cardConfigs?: HeroConfig[];
  [key: string]: any;
}
export default function FeaturesSection({
  type,
  height,
  cardConfigs,
  ...props
}: FeaturesSectionProps) {
  // console.log({
  //   type,
  //   height,
  //   cardConfigs,
  //   ...props,
  // });
  if (!cardConfigs) return <></>;
  return (
    <ContentCardContainer className="">
      <ContentCardInner className="">
        <ContentCardRow className="">
          <ContentCardHorzBar className="ContentCardHorzBar">
            <div className="">
              <hr />
            </div>
          </ContentCardHorzBar>
          {cardConfigs.map((cardConfig, idx) => {
            return (
              <React.Fragment key={`${cardConfig.type}-${idx}`}>
                <ContentCardImage className="ContentCardImage">
                  <img
                    src={cardConfig.image?.src}
                    alt={cardConfig.image?.alt}
                    width={cardConfig.image?.width}
                    height={cardConfig.image?.height}
                    // loading={cardConfig.image?.loading || "lazy"}
                  />
                </ContentCardImage>
                <ContentCardContent className="ContentCardContent">
                  <ContentCardContentTextContainer className="">
                    <h3>{cardConfig.title}</h3>
                    <p>{cardConfig.body}</p>
                  </ContentCardContentTextContainer>
                  {cardConfig.ctas && (
                    <ContentCardContentCtaContainer className="">
                      <a
                        title={cardConfig.ctas[0].label}
                        href={cardConfig.ctas[0].to}
                      >
                        <span>{cardConfig.ctas[0].label}</span>
                      </a>
                    </ContentCardContentCtaContainer>
                  )}
                </ContentCardContent>
              </React.Fragment>
            );
          })}
          <ContentCardHorzBar className="">
            <div className="">
              <hr />
            </div>
          </ContentCardHorzBar>
        </ContentCardRow>
      </ContentCardInner>
    </ContentCardContainer>
  );
}
