import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -box-sizing: border-box;

    margin: 0 0 0 0;
    padding: 0 0 0 0;

    --device-sm: 320px;
    --device-mm: 375px;
    --device-lm: 425px;
    --device-stab: 600px;
    --device-tab: 768px;
    --device-ltab: 820px;
    --device-lap: 1024px;
    --device-mlap: 1280px;
    --device-llap: 1440px;
    --device-4k: 2560px;

    --typeface-style: "regular";
    --typeface-style-stylesheet: "https://fonts.googleapis.com/css2?family=Vampiro+One";
    --typeface-style-font-family: 'Open Sans','Source Sans Pro','Vampiro One',"Segoe UI","Roboto","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",'Helvetica',"Noto Color Emoji";;
    --typeface-style-font-style: normal;
    --typeface-style-font-color: #030303;
    --typeface-style-font-color-light: #d4d4d8;
    --typeface-font-size-number: 7.5rem;
    --typeface-style-font-weight: 400;
    --typeface-letterspacing-number: 0em;
    --site-wide-hover-rgba-bg: rgba(131, 191, 212, 0.25);
    --site-wide-hover-hex: var(--primary);
    --site-wide-hover-rgba: var(--primary);

    --primary: #1f3c88;
    --primary-dark: #070d59;
    --primary-gradient-br: linear-gradient(to bottom right, var(--primary-dark), var(--primary));
    --primary-gradient-0: linear-gradient(0deg, var(--primary-dark), var(--primary));
    --primary-gradient-45: linear-gradient(45deg, var(--primary-dark), var(--primary));
    --primary-gradient-90: linear-gradient(90deg, var(--primary-dark), var(--primary));
    --primary-gradient-135: linear-gradient(135deg, var(--primary-dark), var(--primary));
    --primary-gradient-180: linear-gradient(180deg, var(--primary-dark), var(--primary));
    --secondary: #fcfcfc;
    --secondary-dark: #c6c6c6
    --accent: #767981;
    --accent-dark: #222324;

    --text-color: var(--typeface-style-font-color-light);
    --text-color-dark: var(--typeface-style-font-color);

    --hero-height-small: 105vh;
    --hero-height-medium: 120vh;
    --hero-height-large: 100vh;

    --header-height: 5rem;
    --header-height-initial: 7.5rem;
    --header-z-idx: 2006;
    --header-bg-initial: transparent;
    --header-bg: var(--secondary-dark);
    --header-hamburger-bg-intial: var(--bg-secondary);
    --header-hamburger-bg: var(--accent-dark);
    --header-nav-item-text-color: var(--typeface-style-font-color-light);
    --header-nav-item-text-color-dark: var(--primary-dark);

    --footer-height: 27rem;
    --footer-z-idx: 2006;
    --global-gutter-width: 3rem;

    --bg-primary: var(--primary);
    --bg-secondary: var(--secondary);
    --bg-gradient: var(--primary-gradient-45);

    --card-border: 1px solid #535353;
    --card-border: 1px solid var(--accent);
    --card-boxshadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    --button-text-color: var(--text-color-dark);
    --button-text-color-light: var(--text-color);
    --button-bg-color: var(--accent-dark);
    --button-border: 1px solid var(--primary-dark);
    --button-border-radius: 3%;
    --button-box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px;

    --unvisited-selected: rgb(190, 87, 0);
    --complete-selected: rgb(0, 89, 255);
  }

  body{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;

    overflow-x: hidden;
    scrollbar-width: none;
    scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;

    font-family: var(--typeface-style-font-family);
    font-style: var(--typeface-style-font-style);
    color: var(--typeface-style-font-color);
    font-size: calc(0.15 * var(--typeface-font-size-number));
    font-weight: var(--typeface-style-font-weight);
    letter-spacing: var(--typeface-letterspacing-number);

    a {
      text-decoration: none;

      &:hover{
        text-decoration: none;
      }
    }
  }

  .NewUser {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background-color: white;
    border: none;
    transform: translate(250%, 500%);
    padding: 1rem;

    input {
      position: absolute;
      top: -24px;
      left: 0;
      color: var(--text-color-dark);
      z-index: 5;
    }
  }

  /* .fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
    text-align: center;
    border: 0 solid #f0f0f0;
    margin: auto auto;
  } */

  .firebase-emulator-warning {
    display: none;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
  
  @media screen and (prefers-reduced-motion: no-preference) {
    html {
      scroll-behavior: smooth;
    }
  }

  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }

  @media only screen and (min-width: var(--device-stab)) {
    /* For tablets: */
    .col-s-1 {width: 8.33%;}
    .col-s-2 {width: 16.66%;}
    .col-s-3 {width: 25%;}
    .col-s-4 {width: 33.33%;}
    .col-s-5 {width: 41.66%;}
    .col-s-6 {width: 50%;}
    .col-s-7 {width: 58.33%;}
    .col-s-8 {width: 66.66%;}
    .col-s-9 {width: 75%;}
    .col-s-10 {width: 83.33%;}
    .col-s-11 {width: 91.66%;}
    .col-s-12 {width: 100%;}
  }

  @media only screen and (min-width: var(--device-tab)) {
    /* For desktop: */
    .col-1 {width: 8.33%;}
    .col-2 {width: 16.66%;}
    .col-3 {width: 25%;}
    .col-4 {width: 33.33%;}
    .col-5 {width: 41.66%;}
    .col-6 {width: 50%;}
    .col-7 {width: 58.33%;}
    .col-8 {width: 66.66%;}
    .col-9 {width: 75%;}
    .col-10 {width: 83.33%;}
    .col-11 {width: 91.66%;}
    .col-12 {width: 100%;}
  }
`;
