import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import styled from "styled-components";

import { useFirebaseService } from "../../../../contexts";
import { InfoRequest, ROUTES } from "../../../../globals";

export const ContactFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;
export const ContactInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const ContactInput = styled.input`
  display: flex;
  margin: 5px;
  padding: 7px 0.5rem;
  border: var(--border);
  border-radius: 5px;
  width: 90%;
  background-color: #f5f8fa;
`;
export const ContactSelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 98.5%; //20em;
  height: 3em;
  margin: 5px;
  line-height: 3;
  background-color: #f5f8fa;
  overflow: hidden;
  border-radius: 0.25em;

  &:after {
    content: "";
    position: absolute;
    background-color: #f5f8fa;
    top: 0;
    right: 0;
    /* padding: 0 1em; */
    /* background: #34495e; */
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
  }
`;
export const ContactSelect = styled.select`
  --select-focus: blue;
  --select-arrow: var(--select-border);

  flex: 1;
  outline: 0;
  box-shadow: none;
  border: 0;
  background-color: #f5f8fa;
  background-image: none;
  color: #606060;
  cursor: pointer;
  width: auto;
  /* padding: 3px 5px; */
  margin: 0rem 8px 0.5rem 8px;
`;
export const ContactTextArea = styled.textarea`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98.5%;
  min-height: 104px;
  padding: 3px 5px;
  margin: 2rem 5px 0.5rem 5px;
  border: var(--border);
  border-radius: 5px;
  background-color: #f5f8fa;
`;

interface CommonButtonProps {
  showBackLink?: boolean;
}
export const ContactButtonContainer = styled.div<CommonButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ showBackLink }) =>
    showBackLink ? "space-between" : "center"};
  width: 100%;
  padding: 0px 0.5rem;
  & > a {
    display: ${({ showBackLink }) => (showBackLink ? "flex" : "none")};
  }
`;
export const ContactBack = styled(Link)<CommonButtonProps>`
  display: initial;
  justify-content: center;
  color: var(--text-color);
  text-decoration: none;
  align-items: center;
`;
export const ContactSubmit = styled.button`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 36px;
  background-color: var(--button-bg-color);
  border: var(--button-border);
  border-radius: var(--button-border-radius);
  box-shadow: var(--button-boxshadow);
  & > h4 {
    color: var(--text-color);
  }
`;

const grabFormValues = (refs: Record<string, React.MutableRefObject<any>>) => {
  const _data: Record<any, any> = {};
  return Object.keys(refs).reduce((prev, curr) => {
    prev[curr] = refs[curr].current.value;
    return prev;
  }, _data);
};

const resetFormRefs = (refs: Record<string, React.MutableRefObject<any>>) => {
  Object.keys(refs).forEach((key) => {
    if (key === "reason") refs[key].current.value = "default";
    refs[key].current.value = "";
  });
};

const isValidFormData = (data: Record<any, any>) => {
  return Object.keys(data).reduce((prev, curr) => {
    if (["phone", "company"].includes(curr)) return prev;
    return prev && ![null, "", "default"].includes(data[curr]);
  }, true);
};

export interface ContactFormProps {
  showAlert?: any;
  passCloseAlert?: any;
  passAlertObject?: any;
}

const ContactForm = React.forwardRef(
  (
    { showAlert, passCloseAlert, passAlertObject }: ContactFormProps,
    ref: React.Ref<HTMLFormElement>
  ) => {
    const FireService = useFirebaseService();
    const location = useLocation();
    const fullNameRef = useRef(null);
    const companyRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const selectRef = useRef(null);
    const messageRef = useRef(null);

   const formDataRefs: Record<
     string,
     React.MutableRefObject<HTMLElement | null>
   > = {
     name: fullNameRef,
     company: companyRef,
     email: emailRef,
     phone: phoneRef,
     reason: selectRef,
     message: messageRef,
   };
   const reasonOptions: Record<string, string> = {
     default: "Please Select *",
     general: "General Inquiry",
     manager: "Talk to an Account Manager",
     service: "Service Desk",
     other: "Other",
   };
   const [showBackLink, setShowBackLink] = useState(false);

   useEffect(() => {
     setShowBackLink(location && location.pathname !== ROUTES.LANDING);
   }, [location]);

   const getRecaptchaConfig = () => {
     return process.env.REACT_APP_RECAPTCHA_SITE_KEY;
   };

  const recaptchaExecution = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    action: string
  ): Promise<string> => {
    e.preventDefault();
    return new Promise((resolve, reject) => {
      const recap = (window as { [key: string]: any })["grecaptcha"];
      if (!recap) return resolve("");
      recap.ready(function () {
        recap
          .execute(`${getRecaptchaConfig()}`, {
            action,
          })
          .then((token: string) => {
            if (token) resolve(token);
          })
          .catch((error: any) => {
            console.error(error);
            reject(error);
          });
      });
    });
  };
  const handleFormSubmit: React.MouseEventHandler<HTMLButtonElement> = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      const formData: InfoRequest = grabFormValues(formDataRefs);
      const reToken = await recaptchaExecution(e, "requestForInfo");
      formData["reCaptchaToken"] = reToken;
      formData["reason"] = reasonOptions[formData["reason"] || "Other"];
      if (!isValidFormData(formData)) throw new Error(`Invalid Form data`);

      const response = await FireService.doRequestInformation(formData);

      if (response) {
        // passAlertObject({
        //   type: 'success',
        //   header: 'Request Sent',
        //   message:
        //     'Your request for more information has been submitted and a representative will reach out to you shortly.',
        // });
        alert(
          "Your request for more information has been submitted and a representative will reach out to you shortly."
        );
        resetFormRefs(formDataRefs);
      }
    } catch (error) {
      console.log(
        "An error occurred while attempting to request more information:",
        { error }
      );
      alert("An error occurred while attempting to request more information");
      // passAlertObject({
      //   type: 'danger',
      //   header: error.code,
      //   message: error.message,
      // });
    }
  };
    return (
      <ContactFormContainer ref={ref} onSubmit={(e) => e.preventDefault()}>
        {showBackLink && <h4>Sign-up to get started now</h4>}
        <ContactInputWrapper>
          <ContactInput
            type="text"
            name="fname"
            placeholder="Full Name *"
            ref={fullNameRef}
            required
            aria-required={true}
            style={{ width: "100%" }}
          />
        </ContactInputWrapper>
        <ContactInputWrapper>
          <ContactInput
            type="text"
            name="email"
            placeholder="Email *"
            ref={emailRef}
            required
            aria-required={true}
            style={{ width: "100%" }}
          />
          <ContactInput
            type="text"
            name="phoneNumber"
            placeholder="Phone #"
            ref={phoneRef}
          />
        </ContactInputWrapper>
        <ContactInputWrapper>
          <ContactInput
            type="text"
            name="companyName"
            ref={companyRef}
            placeholder="Company Name"
            style={{ width: "100%" }}
          />
        </ContactInputWrapper>
        <ContactSelectWrapper>
          <ContactSelect
            id="reasons"
            name="reasons"
            ref={selectRef}
            required
            aria-required={true}
            defaultValue={Object.keys(reasonOptions)[0]}
          >
            {Object.keys(reasonOptions).map((key, idx) => (
              <option
                key={idx + key}
                disabled={idx === 0}
                value={key}
              >
                {reasonOptions[key]}
              </option>
            ))}
          </ContactSelect>
        </ContactSelectWrapper>
        <ContactTextArea
          rows={2}
          placeholder="Message *"
          ref={messageRef}
          id="message-area"
          onFocus={(e) => (e.target.rows = 5)}
          required
          aria-required={true}
        />
        <ContactButtonContainer showBackLink={showBackLink}>
          <ContactBack to={ROUTES.HOME}>
            <FaArrowLeft style={{ paddingRight: "5px" }} />
            Back
          </ContactBack>
          <ContactSubmit
            type="submit"
            data-action="requestForInfo"
            onClick={handleFormSubmit}
          >
            <h4>{showBackLink ? "Send Request" : "Send"}</h4>
          </ContactSubmit>
        </ContactButtonContainer>
      </ContactFormContainer>
    );
  }
);

export default ContactForm;
