import React, { useEffect, useLayoutEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { FirebaseProvider } from "./contexts";

import { GlobalStyle } from "./globalStyles";
import { HeaderConfig, RouteConfig, FooterConfig } from "./globals";

import Header, { HeaderProps } from "./components/Header/header";
import Footer, { FooterProps } from "./components/Footer/footer";
import BackToTop from "./components/BackToTop/backToTop";

import * as cfgs from "./configs";

const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, #070d59, #1f3c88);
`;

const AppContent = styled.div`
`;

export const App = () => {
  const [scrollPos, setScrollPos] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showBackTotop, setShowBackTotop] = useState(false);
  // const [showHeader, setShowHeader] = useState(true);
  const [shouldFadeHeader, setShouldFadeHeader] = useState(false);

  const toggle = (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    setIsOpen((prev) => !prev);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPos(position);
    setShowBackTotop(scrollPos > 600);
    // setShowHeader(position < scrollPos);
    setShouldFadeHeader(position > 100);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    // console.log({
    //   headConfig: cfgs.HEADER_CONFIG,
    //   pageConfig: cfgs.ROUTE_CONFIGS,
    //   footerConfig: cfgs.FOOTER_CONFIG,
    // });
    // console.log({
    //   scrollPos,
    //   isOpen,
    //   showBackTotop,
    //   showHeader,
    //   shouldFadeHeader,
    // });
  }, []);
  
  return (
    <>
      <GlobalStyle />
      <AppContainer id="AppContainer">
        <BrowserRouter>
          <FirebaseProvider>
            <AppContent id="AppContent">
              <Header
                {...generateHeaderConfig(
                  cfgs.HEADER_CONFIG,
                  toggle,
                  isOpen,
                  true,
                  shouldFadeHeader
                )}
              />
              <Routes>{generateRoutes(cfgs.ROUTE_CONFIGS)}</Routes>
              <BackToTop show={showBackTotop} />
            </AppContent>
          </FirebaseProvider>
          <Footer {...generateFooterConfig(cfgs.FOOTER_CONFIG)} />
        </BrowserRouter>
      </AppContainer>
    </>
  );
};

export default App;

const generateHeaderConfig = (
  config: HeaderConfig,
  toggle: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void,
  isOpen: boolean,
  showHeader: boolean,
  shouldFadeHeader: boolean,
) => {
  return {
    ...config,
    toggle,
    showSub: isOpen,
    show: showHeader,
    transition: shouldFadeHeader,
  } as HeaderProps;
};

const generateRoutes = (config: RouteConfig[]) => {
  // console.log({ config });
  return config.map((cfg, idx) => {
    return (
      cfg.component &&
      cfg.componentProps && (
        <Route
          key={cfg.path + idx}
          path={cfg.path}
          element={<cfg.component {...cfg.componentProps} />}
        />
      )
    );
  });
};
const generateFooterConfig = (config: FooterConfig) => {
  return {
    ...config,
  } as FooterProps;
};
