import { FaChevronUp } from "react-icons/fa";
import styled from "styled-components";

const BackToTopContainer = styled.a`
  position: fixed;
  bottom: 80px;
  right: 15px;
  height: auto;
  margin: 0;
  z-index: 10000;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  color: #cdcdcd;
  text-decoration: none;
  cursor: pointer;
  &.on {
    opacity: 1;
    visibility: visible;
  }
  &.off {
    opacity: 0;
    visibility: hidden;
  }

  & > span {
    position: relative;
    width: 52px;
    height: 52px;

    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;

    text-align: center;
    line-height: 52px;
    text-decoration: none;

    background-color: rgba(60, 60, 60, 0.8);
    border: 1px solid rgba(60, 60, 60, 1);
    border-radius: 50%;
  }
`;

const BackToTop = ({ show }: { show: boolean }) => (
  <BackToTopContainer href="#" className={show ? "on" : "off"}>
    <span>
      <FaChevronUp />
    </span>
  </BackToTopContainer>
);

export default BackToTop;
