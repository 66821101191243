import { Link as LinkR } from "react-router-dom";
import styled from "styled-components";

const LogoContainer = styled(LinkR)`
  width: auto;
  height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  [id*="Logo"] {
    height: 100%;
    width: auto;
    padding: 1rem;
    opacity: 1;
    transition: opacity 0.6s ease-in-out;
    background-color: transparent;
  }

  #LogoLarge,
  #LogoMedium {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    #LogoLarge,
    #LogoSmall {
      display: none;
    }
    #LogoMedium {
      display: flex;
      padding: 1.5rem 0;
    }
  }
  @media only screen and (min-width: 1024px) {
    #LogoMedium,
    #LogoSmall {
      display: none;
    }
    #LogoLarge {
      display: flex;
    }
  }
`;

export interface HeaderLogoProps {
  className?: string;
  id?: string;
  to: string;
  useDark: boolean;
  imgSrcSmall: string;
  imgSrcMedium: string;
  imgSrcLarge: string;
  imgSrcSmallDark: string;
  imgSrcMediumDark: string;
  imgSrcLargeDark: string;
  [key: string]: any;
}
const HeaderLogo = ({
  className,
  id,
  to,
  useDark,
  imgSrcSmall,
  imgSrcSmallDark,
  imgSrcMedium,
  imgSrcMediumDark,
  imgSrcLarge,
  imgSrcLargeDark,
  ...props
}: HeaderLogoProps) => {
  
  return (
    <LogoContainer className={className} id={id} to={to} {...props}>
      <img
        id="LogoSmall"
        itemProp="image"
        src={useDark ? imgSrcSmallDark : imgSrcSmall}
        alt="Logo"
      />
      <img
        id="LogoMedium"
        itemProp="image"
        src={useDark ? imgSrcMediumDark : imgSrcMedium}
        alt="Logo"
      />
      <img
        id="LogoLarge"
        itemProp="image"
        src={useDark ? imgSrcLargeDark : imgSrcLarge}
        alt="Logo"
      />
    </LogoContainer>
  );
};
export default HeaderLogo;
