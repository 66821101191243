import styled from "styled-components";
interface HeroHeight {
  smallScreen?: string;
  mediumScreen?: string;
  largeScreen?: string;
}
export const HeroContainer = styled.section<HeroHeight>`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: ${({ smallScreen }) => smallScreen || "var(--hero-height-small)"};

  @media only screen and (min-width: 768px) {
    height: ${({ mediumScreen }) => mediumScreen || "var(--hero-height-medium)"};
  }

  @media only screen and (min-width: 1024px) {
    height: ${({ largeScreen }) => largeScreen || "var(--hero-height-large)"};
  }
`;

export const BaseContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;


export const CarouselContainer = styled(BaseContainer)`
  .left {
    flex: 5%;
    height: 100%;
    background-color: rgba(15, 7, 6, 0.5);
    display: grid;
    place-content: center;
    color: var(--text-color);
  }
  .center {
    display: grid;
    place-content: center;
    flex: 90%;
    height: 100%;
    background-color: rgba(15, 7, 6, 0.5);
    color: var(--text-color);
    text-align: center;
    font-family: "Open Sans", Sans-serif;
    font-size: 3em;
    font-weight: 600;
    line-height: 3em;
  }
  .right {
    flex: 5%;
    height: 100%;
    background-color: rgba(15, 7, 6, 0.5);
    display: grid;
    place-content: center;
    color: var(--text-color);
  }
  transition: all 1s;
`;

