import Hero, { HeroProps } from "../../../../components/Hero/hero";
import { HeroType, HeroConfig } from "../../../../globals";

export interface CronosSectionProps extends HeroProps {
  height?: string;
  cardConfig?: HeroConfig;
  [key: string]: any;
}
export default function CronosSection({
  height,
  cardConfig,
  ...props
}: CronosSectionProps) {
  // console.log({
  //   type,
  //   height,
  //   heroConfig,
  //   ...props,
  // });
  return (
    <Hero
      type={HeroType.Content}
      height={height}
      subHeros={[cardConfig]}
      {...props}
    />
  );
}
