import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { BaseElementProps } from "../../../globals";

const HamburgerContainer = styled(LinkR)<BaseElementProps>`
  display: flex;
  flex: 1 0 auto;
  margin: auto 0;
  padding: 0 1.5rem;
  color: ${({ fontColor }) => fontColor || "var(--header-hamburger-bg-intial)"};
  filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4)); 
  
  @media only screen and
    (min-width: 1024px) {
    display: none;
  }
`;

export interface HamurgerProps {
  className?: string;
  id?: string;
  toggle: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}
const Hamburger = ({ className, id, toggle }: HamurgerProps) => {
  return (
    <HamburgerContainer to="#" className={className} id={id} onClick={toggle}>
      <FaBars size={36} />
    </HamburgerContainer>
  );
};

export default Hamburger;
